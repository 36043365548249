import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RoutePath } from '@shared/enums/route-path.enum';
import { CheckForTokenGuard } from '@shared/guards/check-for-token.guard';
import { ErrorAuthGuard } from '@shared/guards/error-auth.guard';
import { LoginRouteGuard } from '@shared/guards/login-route.guard';

// Use the AuthGuard for protected routes
const routes: Routes = [
  {
    path: RoutePath.LOGIN,
    loadChildren: () => import('@app/modules/login/login.module').then(m => m.LoginModule),
  },
  {
    path: RoutePath.ACCEPT_TOKEN,
    loadChildren: () => import('@app/modules/accept-token/accept-token.module').then(m => m.AcceptTokenModule),
  },
  {
    path: RoutePath.NO_PERMISSION,
    canActivate: [ErrorAuthGuard],
    loadComponent: () =>
      import('@app/modules/main-application/no-permission/no-permission.component').then(m => m.NoPermissionComponent),
  },
  {
    path: RoutePath.WIDGET_BOARD_RESCHEDULE,
    loadComponent: () =>
      import('@widgets/board-builder/reschedule-widget/reschedule-widget.component').then(
        m => m.RescheduleWidgetComponent
      ),
  },
  {
    path: RoutePath.WIDGET_BOARD_DONE,
    canActivate: [CheckForTokenGuard],
    loadComponent: () => import('@widgets/board-builder/done-widget.component').then(m => m.DoneWidgetComponent),
  },
  {
    path: RoutePath.WIDGET_BOARD_COMMENT,
    canActivate: [CheckForTokenGuard],
    loadComponent: () => import('@widgets/board-builder/comment-widget.component').then(m => m.CommentWidgetComponent),
  },
  {
    path: RoutePath.WIDGET_BOARD_TURNOVER_COMMENT,
    canActivate: [CheckForTokenGuard],
    loadComponent: () =>
      import('@widgets/turnover-comment/turnover-comment-widget.component').then(m => m.TurnoverCommentWidgetComponent),
  },
  {
    path: RoutePath.WIDGET_BOARD_TURNOVER_DECLINE,
    canActivate: [CheckForTokenGuard],
    loadComponent: () =>
      import('@widgets/turnover-decline/turnover-decline.component').then(m => m.TurnoverDeclineComponent),
  },
  {
    path: RoutePath.WIDGET_BOARD_TURNOVER_INPROGRESS,
    canActivate: [CheckForTokenGuard],
    loadComponent: () =>
      import('@widgets/turnover-inprogress/turnover-inprogress.component').then(m => m.TurnoverInprogressComponent),
  },
  {
    path: RoutePath.WIDGET_BID_ACCEPT,
    canActivate: [CheckForTokenGuard],
    loadComponent: () => import('@widgets/bid-management/accept-widget.component').then(m => m.AcceptWidgetComponent),
  },
  {
    path: RoutePath.WIDGET_BID_REJECT,
    loadComponent: () => import('@widgets/bid-management/reject-widget.component').then(m => m.RejectWidgetComponent),
  },
  {
    path: RoutePath.WIDGET_BID_APPROVE,
    canActivate: [CheckForTokenGuard],
    loadComponent: () => import('@widgets/bid-management/approve-widget.component').then(m => m.ApproveWidgetComponent),
  },
  {
    path: RoutePath.WIDGET_BID_DENY,
    loadComponent: () => import('@widgets/bid-management/deny-widget.component').then(m => m.DenyWidgetComponent),
  },
  {
    path: RoutePath.WIDGET_BID_COMMENT,
    canActivate: [CheckForTokenGuard],
    loadComponent: () => import('@widgets/bid-management/comment-widget.component').then(m => m.CommentWidgetComponent),
  },
  {
    path: RoutePath.OPEN_MOBILE_APP,
    loadComponent: () =>
      import('@widgets/open-mobile-app/open-mobile-app.component').then(m => m.OpenMobileAppComponent),
  },
  {
    path: RoutePath.DOWNLOAD_MOBILE_APP,
    loadComponent: () =>
      import('@widgets/download-mobile-app/download-mobile-app.component').then(m => m.DownloadMobileAppComponent),
  },
  {
    path: ``,
    canActivate: [LoginRouteGuard],
    loadChildren: () => import('@main-application/main-application.module').then(m => m.MainApplicationModule),
  },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
