import { ScoreWeightModes, ScoringModels } from '@main-application/inspections/models/rest-inspections-model.interface';
import { RestTemplateAreaModel } from '@template/models/rest-template-model.interface';

export const TemplateEditorDefaultValue = 'Edit me!';
export const TemplateEditorInstructionsDefaultValue = 'Provide some helpful instructions...';
export const TemplateMaxDescriptionLength = 150;
export const TemplateMaxAreaAndItemLength = 256;

export enum RepeatableArea {
  Bedroom,
  Bathroom,
  HalfBathroom,
  AnyBathRoom,
  PostCompleteSurvey,
}

export interface AreaModel extends RestTemplateAreaModel {
  areaSurvey: AreaSurveyModel;
}

export interface AreaSurveyModel {
  title: string;
  pages: AreaPageModel[];
  logoPosition?: string;
  surveyId?: string;
  scoringModel?: ScoringModels;
  resultScore?: number;
  scoreWeightMode?: ScoreWeightModes;
  scoreWeight?: number;
  itemsScore?: number;
  showInstructions?: boolean;
  instructions?: string;
  sparePartsGroups?: number[];
  showSpareParts?: boolean;
}

export type CompulsoryItems =
  | 'photoRequired'
  | 'commentRequired'
  | 'hasInstructions'
  | 'showHoursWidget'
  | 'ticketRequired'
  | 'showSpareParts'
  | 'sparePartsSelectionRequired';

export interface AreaPageModel {
  uuid: string;
  name: string;
  title: string;
  instructions?: string;
  compulsoryItems: CompulsoryItems[];
  pageType?: 'area';
  timeCost?: number;
  materials?: number;
  laborCost?: number;
  scoreWeightMode?: ScoreWeightModes;
  scoreWeight?: number;
  elements: AreaPageElementAny[];
  overrideSparePartsSettings?: boolean;
  showSpareParts?: boolean;
  sparePartsGroups?: number[];
}

export interface RestTemplateEditorRatingGroup {
  name: string;
  itemsJson: string;
  id: number;
}

export interface TemplateEditorRatingGroupItem {
  item: {
    backgroundColor: string;
    emoji: string;
    id: string;
    onSelect: CompulsoryItems[];
    text: string;
    isDefault: boolean;
    showQtyWidget?: boolean;
    ticketRequired?: boolean;
    showSpareParts?: boolean;
    sparePartsSelectionRequired?: boolean;
    showHoursWidget?: boolean;
    timeMultiplier?: number;
    materialsMultiplier?: number;
    resultScore?: number;
  };
}

export interface TemplateEditorRatingGroup {
  name: string;
  items: TemplateEditorRatingGroupItem[];
  id: number;
}

export interface TemplateEditorSparePartsGroup {
  sparePartIds: number[];
  createdBy: number;
  isDeleted: boolean;
  dateCreated: string;
  title: string;
  id: number;
  propertyOrPortfolioId?: number;
  parentGroupId?: number;
  hierarchyPath: string;
}

export interface TemplateEditorEmojiContent {
  id: 0;
  emojiCode: string;
  title: string;
  keywords: string[];
  assetRelativeUrl: string;
}

export type AreaPageElementAny =
  | AreaPageElementFileModel
  | AreaPageElementCommentModel
  | AreaPageElementRatingGroupModel
  | AreaPageElementQuestionModel
  | AreaPageElementQuantityModel
  | AreaPageElementHoursModel;

export abstract class AreaPageElementModel {
  type: 'file' | 'comment' | 'boolean' | 'propertystate' | 'quantity' | 'hours';
  title: string;
  name: string;
  guid?: string;
}

export class AreaPageElementFileModel extends AreaPageElementModel {
  type: 'file';
  description: string;
}

export class AreaPageElementQuantityModel extends AreaPageElementModel {
  type: 'quantity';
  isRequired?: boolean;
  value: any;
}

export class AreaPageElementHoursModel extends AreaPageElementModel {
  type: 'hours';
  isRequired?: boolean;
  value: any;
}

export class AreaPageElementCommentModel extends AreaPageElementModel {
  type: 'comment';
}

export class AreaPageElementRatingGroupModel extends AreaPageElementModel {
  type: 'propertystate';
  ratingGroupId: number;
  items: TemplateEditorRatingGroupItem[];
  isRequired?: boolean;
}

export class AreaPageElementQuestionModel extends AreaPageElementModel {
  type: 'boolean';
  guid: string;
  name: string;
  title: string;
  defaultValue?: boolean;
}

export interface RestCustomInspectionModel {
  id?: number;
  inspectionName: string;
  isRequired?: boolean;
}
