import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, catchError, from } from 'rxjs';
import { filter, map, mergeMap, reduce, tap } from 'rxjs/operators';

import { FileUploadsService } from '@app/services/file-uploads.service';
import { GenerateThumbnailService } from '@app/services/generate-thumbnail.service';
import { checkSupportedFormatFunction } from '@shared/functions/check-supported-format.function';
import { Upload } from '@shared/interfaces/upload';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { CustomControlAbstract } from '@ui-components/controls/custom-control.abstract';

@UntilDestroy()
@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFileComponent extends CustomControlAbstract<any> implements OnInit, ControlValueAccessor {
  errors: ValidationErrors;
  invalid = false;
  disabled = false;

  @Input() label = '';
  @Input() labelRequired = false;
  @Input() labelCss = 'nowrap body-small-bold';
  @Input() contentCss = 'display-flex  align-items-start';
  @Input() containerCss = 'display-flex flex-column';
  @Input() inputCss = '';
  @Input() multiselect = false;
  @Input() acceptedFormats = ['application/pdf'];
  @Input() attrPlaceholder = 'Upload a file';
  @Input() uploadSelection = false;
  @Input() attrDisable = false;
  @Input() isFileLoaded: boolean | null = null;
  @Input() labelTooltipText: string = null;
  @Input() isResident = false;
  @Input() useTwoStepUpload = false;

  @Input() set value(value: string) {
    this.control.setValue(value);
  }

  @Output() clearEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() selectFilesEvent: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() uploadInProgress = new EventEmitter<boolean>();

  @ViewChild('fileUpload', { static: true }) input: ElementRef;

  uploadingFile$ = new BehaviorSubject<File | null>(null);
  uploadingProgress$ = new BehaviorSubject<number>(0);

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    @Optional() formDirective: FormGroupDirective,
    protected cdr: ChangeDetectorRef,
    private fileUploadsService: FileUploadsService,
    private snackbarService: SnackbarService,
    private generateThumbnailService: GenerateThumbnailService
  ) {
    super(ngControl, cdr, formDirective);
  }

  ngOnInit(): void {
    this.initControlChanges();
    this.initCheckControl();
  }

  writeValue(value: any): void {
    this.control.setValue(value);
  }

  browseFile() {
    this.input.nativeElement.value = '';
    this.input.nativeElement.click();
  }

  clear() {
    this.control.reset();
    this.clearEvent.emit();
    this.cdr.detectChanges();
  }

  filesDropped(files: File[]) {
    this.uploadSelection ? this.uploadFile(files) : this.emitEvents(files);
  }

  inputFileChanged($event: Event) {
    const files: FileList = ($event.target as HTMLInputElement).files;
    const filesList = Object.entries(files).map<File>(([key, file]) => file);
    this.uploadSelection ? this.uploadFile(filesList) : this.emitEvents(filesList);
  }

  private emitEvents(files: File[]) {
    if (files && files.length) {
      this.selectFilesEvent.emit(files);
      this.control.setValue(files);
      this.cdr.detectChanges();
    }
  }

  private uploadFile(files: File[]) {
    const filesToUpload = files.filter(file => checkSupportedFormatFunction(file, this.acceptedFormats));
    if (filesToUpload.length > 0) {
      this.uploadingFile$.next(filesToUpload[0]);
      this.uploadingProgress$.next(0);
      this.uploadInProgress.emit(true);
      this.cdr.detectChanges();

      if (this.useTwoStepUpload) {
        this.uploadQueue(filesToUpload, this.doTwoStepUpload.bind(this));
      } else {
        this.uploadQueue(filesToUpload, this.doUpload.bind(this));
      }
    } else {
      this.snackbarService.error(SnackbarErrorMessage.UnsupportedFileType);
    }
  }

  private uploadQueue(files: File[], uploadMethod: (file: File) => any) {
    from(files)
      .pipe(
        mergeMap((file: File) => uploadMethod(file), 1),
        reduce((acc, value) => {
          acc.push(value);
          return acc;
        }, [])
      )
      .subscribe({
        next: result => {
          this.control.setValue(result);
          this.uploadingProgress$.next(100);
          this.cdr.detectChanges();
        },
        complete: () => {
          this.uploadInProgress.emit(false);
          this.cdr.detectChanges();
        },
      });
  }

  getMimeType(file: File): string {
    if (file.type) {
      return file.type;
    }

    const extension = file.name.split('.').pop()?.toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'mp4':
        return 'video/mp4';
      case 'gif':
        return 'image/gif';
      default:
        return 'application/octet-stream';
    }
  }

  private isVideoFileByExtension(fileName: string): boolean {
    const videoExtensions = ['.mp4', '.mov', '.avi', '.wmv', '.mkv'];
    const fileExtension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();
    return videoExtensions.includes(fileExtension);
  }

  private doTwoStepUpload(file: File): Observable<any> {
    return new Observable(observer => {
      const isVideo =
        file.type && file.type !== '' ? file.type.startsWith('video/') : this.isVideoFileByExtension(file.name);

      if (isVideo) {
        this.generateThumbnailService.generateThumbnail(file).subscribe({
          next: ({ thumbnail, duration }) => {
            this.uploadThumbnail(thumbnail as File)
              .pipe(
                mergeMap(thumbnailUploadResponse =>
                  this.uploadMainFile(file, thumbnailUploadResponse.id, duration, thumbnailUploadResponse.cloudUri)
                )
              )
              .subscribe({
                next: response => {
                  observer.next(response);
                  observer.complete();
                },
                error: error => this.handleUploadError(observer, 'Complete file upload error', error),
              });
          },
          error: error => this.handleUploadError(observer, 'Generating thumbnail error', error),
        });
      } else {
        this.uploadMainFile(file, null, null, null).subscribe({
          next: response => {
            observer.next(response);
            observer.complete();
          },
          error: error => this.handleUploadError(observer, 'File upload error', error),
        });
      }
    });
  }

  private uploadThumbnail(thumbnail: File): Observable<any> {
    return this.fileUploadsService.uploadFile(thumbnail);
  }

  private uploadMainFile(file: File, thumbnailId: number, duration: number, thumbnailUri: string): Observable<any> {
    return new Observable(observer => {
      this.fileUploadsService.generateUploadLink(encodeURIComponent(file.name)).subscribe({
        next: response => {
          this.uploadFileToServer(file, response.urlToUpload, thumbnailId, duration, thumbnailUri, observer);
        },
        error: error => this.handleUploadError(observer, 'Upload link error', error),
      });
    });
  }

  private uploadFileToServer(
    file: File,
    uploadUrl: string,
    thumbnailId: number,
    duration: number,
    thumbnailUri: string,
    observer: any
  ) {
    const xhr = new XMLHttpRequest();
    xhr.upload.onprogress = this.trackProgress.bind(this);

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        this.completeFileUpload(xhr.responseURL, thumbnailId, duration, thumbnailUri, observer);
      } else {
        this.handleUploadError(observer, `Upload error: ${xhr.statusText}`, new Error(xhr.statusText));
      }
    };

    xhr.onerror = () => this.handleUploadError(observer, `Upload error: ${xhr.statusText}`, new Error(xhr.statusText));

    xhr.open('PUT', uploadUrl, true);
    this.setXhrHeaders(xhr, file);
    xhr.send(file);
  }

  private completeFileUpload(
    responseURL: string,
    thumbnailId: number,
    duration: number,
    thumbnailUri: string,
    observer: any
  ) {
    this.fileUploadsService.completeFileUpload(responseURL, thumbnailId, Math.round(duration) || null).subscribe({
      next: completeResponse => {
        observer.next({ ...completeResponse, thumbnailUri });
        observer.complete();
      },
      error: error => this.handleUploadError(observer, 'Complete file upload error', error),
    });
  }

  private setXhrHeaders(xhr: XMLHttpRequest, file: File) {
    xhr.setRequestHeader('x-ms-date', new Date().toUTCString());
    xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');
    xhr.setRequestHeader('x-ms-meta-filename', encodeURIComponent(file.name));
    xhr.setRequestHeader('Content-Type', this.getMimeType(file));
  }

  private trackProgress(event: ProgressEvent) {
    if (event.lengthComputable) {
      const progress = Math.round((100 * event.loaded) / event.total);
      this.uploadingProgress$.next(progress);
      this.cdr.detectChanges();
    }
  }

  private handleUploadError(observer: any, message: string, error: any): void {
    console.error(message, error);
    this.uploadingProgress$.next(0);
    observer.error(error);
  }

  private doUpload(file: File) {
    return this.fileUploadsService
      .uploadFileProgress(file)
      .pipe(
        catchError((err: unknown) => {
          this.snackbarService.error(SnackbarErrorMessage.UploadingFile);
          this.uploadingProgress$.next(0);
          throw err;
        })
      )
      .pipe(
        tap((event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
            const progress = Math.round((100 * event.loaded) / event.total);
            this.uploadingProgress$.next(progress);
            this.cdr.detectChanges();
          }
        }),
        filter((event: any) => {
          return event instanceof HttpResponse;
        }),
        map((event: any) => {
          this.uploadingProgress$.next(0);
          this.cdr.detectChanges();
          const upload = event.body as Upload;
          return upload;
        })
      );
  }

  private initCheckControl(): void {
    if (this.ngControl?.control) {
      if (this.ngControl.control.errors) {
        this.errors = { ...this.ngControl.control.errors };
      }
      if (this.ngControl.control.touched) {
        this.control.markAsTouched();
      }
      this.invalid = this.ngControl.control.invalid;
      this.ngControl.control.statusChanges
        .pipe(untilDestroyed(this))
        .subscribe(status => this.checkControlStatus(status));
    }
  }

  private initControlChanges(): void {
    this.control.valueChanges
      .pipe(
        tap(value => {
          this.onChanged(value);
          this.onTouched();
          if (!value) {
            this.uploadingFile$.next(null);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }
}
