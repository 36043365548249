import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormGroupDirective, NgControl } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { find } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, map } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { UserType } from '@shared/enums/user-type';
import { getUserList } from '@shared/functions/get-user-list.function';
import { IRadioButtonOption, IUserRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { UserData } from '@shared/interfaces/user-data';
import { RestUserModelExtended } from '@shared/interfaces/user.interface';
import { EnumerationValuePipe } from '@shared/pipes/enumeration-value.pipe';
import { filterNullish$ } from '@shared/utils/rxjs/filter-nullish.rxjs.util';
import { skipEqual$ } from '@shared/utils/rxjs/skip-equal.rxjs.util';
import { CustomControlAbstract } from '@ui-components/controls/custom-control.abstract';

@UntilDestroy()
@Component({
  selector: 'app-user-select-dropdown',
  templateUrl: './user-select-dropdown.component.html',
  styleUrls: ['./user-select-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class UserSelectDropdownComponent
  extends CustomControlAbstract<number>
  implements OnInit, OnChanges, ControlValueAccessor, AfterContentChecked
{
  displayValue = '';
  residentList$ = new BehaviorSubject<IUserRadioButtonOption<number>[]>([]);
  userList$ = new BehaviorSubject<IUserRadioButtonOption<number>[]>([]);
  vendorList$ = new BehaviorSubject<IUserRadioButtonOption<number>[]>([]);
  hasValue = false;
  userTooltipInfo$ = this.initTooltipInfo();
  readonly labelInsideDefaultCss = 'nowrap pre-title';
  readonly labelOutsideDefaultCss = 'nowrap body-small-bold';
  defaultOptionItems: IRadioButtonOption<number>[] = [];

  @ViewChild(NgSelectComponent) ngSelect: NgSelectComponent;

  @Input() selectCss = 'body-small text-color dark';
  @Input() isSmallFont = false;
  @Input() isCustomWidth = false;
  @Input() hasBorders = true;
  @Input() isTransparent = false;
  @Input() containerWidthCss = '';
  @Input() showChevron = true;
  @Input() displaySkeleton = false;

  _labelCss = '';
  @Input() set labelCss(value: string) {
    this._labelCss = value;
  }

  get labelCss(): string {
    if (this._labelCss) return this._labelCss;

    return this.labelInside ? this.labelInsideDefaultCss : this.labelOutsideDefaultCss;
  }

  @Input() set onOpenSelector(openSelector: boolean) {
    setTimeout(() => {
      if (openSelector && this.ngSelect) this.ngSelect.open();
    });
  }

  @Input() containerCss = 'display-flex align-items-center';
  @Input() label = '';
  @Input() attrPlaceholder = '';
  @Input() allowClear = true;
  @Input() allowSearch = true;
  @Input() highlightWhenActive = false;
  @Input() attrDisable = false;
  @Input() labelInside = false;
  @Input() labelRequired: boolean;
  @Input() errorSection = true;
  @Input() markAsInvalid = false;
  @Input() userData: UserData = {};
  @Input() showMyOption = false;
  @Input() showAllOption = false;
  @Input() showAssignToResident = false;
  @Input() defaultOptions: number[] = [];
  @Input() myLabel = 'My Tasks';
  @Input() allLabel = 'All Tasks';
  /*
   * issue available https://github.com/ng-select/ng-select/issues/2055
   * known workaround - https://github.com/Kyotu-Technology/noetic-frontend/pull/455
   */
  @Input() attrAppendTo = '';

  @Input()
  public set isResident(isResident: boolean) {
    this._isResident$.next(isResident ?? false);
  }

  private _isResident$ = new BehaviorSubject<boolean>(false);
  public isResident$ = this._isResident$.pipe();

  @Input()
  public set users(users: RestUserModelExtended[]) {
    this._users$.next(users ?? []);
  }

  private _users$ = new BehaviorSubject<RestUserModelExtended[]>([]);
  public users$ = this._users$.pipe();

  private _valueCss: string;
  @Input() set valueCss(value) {
    this._valueCss = value;
  }

  get valueCss() {
    if (this._valueCss) {
      return this._valueCss;
    }
    return 'ellipsis text-color dark ' + (this.labelInside ? '' : 'body-small');
  }

  private _optionCss: string;
  @Input() set optionCss(value) {
    this._optionCss = value;
  }

  get optionCss() {
    if (this._optionCss) {
      return this._optionCss;
    }
    return 'text-color dark ' + (this.labelInside ? 'pre-option' : 'body-small');
  }

  _listLoading = false;
  @Input() set listLoading(listLoading: boolean) {
    this._listLoading = listLoading;
    this.cdr.detectChanges();
  }

  get myOptionItem(): IRadioButtonOption<number> | null {
    if (!this.showMyOption || !this.userData || this.userData.id === this.control.value) {
      return null;
    }

    return {
      value: this.userData?.id,
      label: this.myLabel,
    };
  }

  get allOptionItem(): IRadioButtonOption<number> | null {
    if (!this.showAllOption || !this.control.value) {
      return null;
    }

    return {
      value: -1,
      label: this.allLabel,
    };
  }

  get residentOptionItem(): IRadioButtonOption<number> | null {
    if (!this.showAssignToResident || !this.control.value) {
      return null;
    }

    return {
      value: -2,
      label: 'Resident (dynamic)',
    };
  }

  labelWidth = '';
  _labelElement: ElementRef<HTMLElement>;

  @ViewChild('labelElement', { static: false }) set content(value: ElementRef<HTMLElement>) {
    if (!value) return;

    this._labelElement = value;
    this.labelWidth = `${value?.nativeElement?.clientWidth}px`;
    this.cdr.detectChanges();
  }

  constructor(
    @Self() @Optional() protected ngControl: NgControl,
    protected cdr: ChangeDetectorRef,
    private enumerationValuePipe: EnumerationValuePipe,
    @Optional() formDirective: FormGroupDirective
  ) {
    super(ngControl, cdr, formDirective);
  }

  ngAfterContentChecked(): void {
    if (!this.labelInside || !this._labelElement) return;

    this.labelWidth = `${this._labelElement?.nativeElement?.clientWidth}px`;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.initControlBase();

    combineLatest([this.isResident$, this.users$.pipe(filter(Boolean))])
      .pipe(
        map(([isResident, users]) => ({ isResident, users })),

        map(({ isResident, users }) => {
          const getUsers = users.map(user => {
            return {
              ...user,
              isFullName: isResident,
            };
          });
          return {
            users: getUsers,
            isResident,
          };
        }),
        untilDestroyed(this)
      )
      .subscribe(({ users, isResident }) => this.prepareUsersList(users, isResident));

    this.transformValue(this.control?.value);
    this.control.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(value => this.transformValue(value));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultOptions || changes.users) {
      this.defaultOptionItems = this.prepareDefaultOptionItems();
    }
  }

  searchFunction(term: string, value: number): boolean {
    term = term.toLowerCase();
    const bothLists = this.userList$.value.concat(this.vendorList$.value);
    const item = find(bothLists, { value });
    return item?.label?.toLowerCase()?.indexOf(term) > -1;
  }

  writeValue(value: number): void {
    if (!this.control) {
      return;
    }

    this.transformValue(value);
    if (value != this.control.value) {
      this.control.setValue(value);
    }
  }

  private prepareDefaultOptionItems(): IRadioButtonOption<number>[] {
    if (this.defaultOptions?.length && (this.userList$.value.length || this.vendorList$.value.length)) {
      return this.userList$.value
        .filter(u => this.defaultOptions.includes(u.value))
        .map(assignee => ({
          value: assignee?.value,
          label: assignee?.label,
        }));
    }
    return [];
  }

  private prepareUsersList(users: RestUserModelExtended[], isResident: boolean) {
    this.residentList$.next([]);
    this.userList$.next([]);
    this.vendorList$.next([]);

    const preparedUsers = getUserList(users);
    const residentListRaw = [];
    const userListRaw = [];
    const vendorListRaw = [];

    preparedUsers.forEach(user => {
      switch (user.userGroupType) {
        case UserType.Resident:
          if (isResident) residentListRaw.push(user);
          break;
        case UserType.Employee:
          userListRaw.push(user);
          break;
        case UserType.Contractor:
          vendorListRaw.push(user);
          break;
      }
    });
    this.userList$.next(userListRaw.sort((a, b) => a.label.localeCompare(b.label)));
    this.vendorList$.next(vendorListRaw.sort((a, b) => a.label.localeCompare(b.label)));
    let combinedList = [...this.userList$.value, ...this.vendorList$.value];

    if (isResident) {
      this.residentList$.next(residentListRaw.sort((a, b) => a.label.localeCompare(b.label)));
      combinedList = [...this.residentList$.value, ...combinedList];
    }

    this.userList$.next(combinedList);
    this.transformValue(this.control.value);
  }

  private transformValue(value: number) {
    if (value < 0) {
      if (value === -1) this.displayValue = this.allLabel;
      if (value === -2) this.displayValue = this.residentOptionItem.label;
    } else if (this.userData?.id === value && this.showMyOption) {
      this.displayValue = this.myLabel;
    } else {
      this.hasValue = !!value;
      if (this.userList$.value.length || this.vendorList$.value.length) {
        const userName = `${this.enumerationValuePipe.transform(value, this.userList$.value, true)}`;
        const vendorName = `${this.enumerationValuePipe.transform(value, this.vendorList$.value, true)}`;
        this.displayValue = userName || vendorName || '';
      }
    }
    this.cdr.detectChanges();
  }

  private initTooltipInfo(): Observable<string> {
    return combineLatest([
      this.control.valueChanges.pipe(filterNullish$()),
      this.userList$.asObservable(),
      this.vendorList$.asObservable(),
    ]).pipe(
      debounceTime(300),
      skipEqual$(),
      map(([id, users, vendors]) => [...users, ...vendors].find(item => item.value === id)?.tooltip)
    );
  }
}
