import { DEFAULT_CURRENCY_CODE, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';

import { environment } from './environments/environment';
import { EnvironmentType } from './environments/environment-config';

if (environment.type === EnvironmentType.Prod) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    providers: [{ provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' }],
  })
  .catch(err => console.error(err));
