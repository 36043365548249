import { extend } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { DropdownColumnOption } from '@main-application/boards/boards-view/boards-cell/cell-types/dropdown-cell/dropdown-cell.component';
import { InspectionType } from '@main-application/inspections/models/rest-inspections-model.interface';
import { WorkflowStepEnumType } from '@shared/enums/workflow-step.enum';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import {
  PaymentStatus,
  RestTurnoverDataModel,
  RestTurnoverTaskModel,
  TotalCostStatus,
} from '@shared/interfaces/turnover.interface';
import { RestUnitModel } from '@shared/interfaces/unit.interface';

import {
  BoardColumnType,
  BoardOutputBehaviour,
  BoardOutputType,
  BoardProgressType,
  BoardSourceType,
  BoardType,
  ScheduleCellStatus,
} from './board.enums';
import { IBoardGroupsConfig } from './borad-groups.interface';
import { CellValueHelper } from '../services/cell-value.helper';

export class RestTurnStepScheduleBaseModel {
  turnoverDateAvailable: Date;
  turnoverDateShowable: Date;
  dateCompleted: Date;
  originalDueDate: Date;
}

export class RestTurnStepScheduleModel extends RestTurnStepScheduleBaseModel {
  id: number;
  turnoverId: number;
  boardColumnUID: string;
  boardLayoutsId: number;
  workflowStepId: number;
  assignee: number;
  assigneeId: number;
  notifyAssignee: boolean;
  dueDate: Date;
  status: ScheduleCellStatus;
  note: string;
  sortOrder: number;
  amount?: number;
  totalCostStatus?: TotalCostStatus;
  paymentStatus?: PaymentStatus;
  inspectionId?: number;
  inspectionType?: InspectionType;
  inspectionTemplateId?: number;
  inspectionItemsCompleted?: number;
  inspectionItemsTotal?: number;

  notifyWhenDoneUserIds?: number[];
  notifyWhenDone?: boolean;

  declinedAt?: string;
  declinedReason?: string;

  pushToPMS?: boolean;
  ticketTopicId?: number;
  turnoverData: RestTurnoverDataModel;

  ticketId?: number;
}

export class RestBoardModel {
  id: number;
  name: string;
  allPortfolios: boolean;
  selectedPortfolioIds?: string;
  boardType: BoardType;
  boardColumnsConfig: string;
  progressType: BoardProgressType;
  datasource: BoardSourceType;
  outputAction: BoardOutputType;
  outputBehaviour: BoardOutputBehaviour;
  boardCellColor: string;
  extendedConfigJson?: string;

  constructor(board: Board) {
    this.id = board.id;
    this.name = board.name;
    this.progressType = board.progressType;
    this.datasource = board.datasource;
    this.boardType = board.boardType;
    this.outputAction = board.outputAction;
    this.outputBehaviour = board.outputBehaviour;
    this.boardCellColor = board.boardCellColor;
    this.allPortfolios = board.allPortfolios;
    this.extendedConfigJson = JSON.stringify(board.extendedConfigParsed);
    this.selectedPortfolioIds = board.selectedPortfolioIds?.length ? board.selectedPortfolioIds.join(',') : undefined;
    this.boardColumnsConfig = JSON.stringify(board.columns.filter(e => e.type !== BoardColumnType.Unselected));
  }
}

function isBoardResponse(entity: any): entity is RestBoardModel {
  return (
    typeof entity.datasource === 'number' &&
    typeof entity.id === 'number' &&
    typeof entity.boardColumnsConfig === 'string' &&
    typeof entity.name === 'string'
  );
}

export interface BoardConfiguration {
  id: number;
  name: string;
  allPortfolios: boolean;
  selectedPortfolioIds?: number[];
  boardType: BoardType;
  progressType: BoardProgressType;
  datasource: BoardSourceType;
  outputAction: BoardOutputType;
  outputBehaviour: BoardOutputBehaviour;
  extendedConfigParsed: IBoardGroupsConfig;
  boardCellColor: string;
}

export interface ColumnOptions {
  editable?: boolean;
  name?: string;
  autoNumber?: boolean;
  inspectionTemplateId?: number;
  options?: DropdownColumnOption[];
}

export class Board implements BoardConfiguration {
  id: number;
  name: string;
  allPortfolios: boolean;
  selectedPortfolioIds?: number[];
  boardType: BoardType;
  progressType: BoardProgressType;
  datasource: BoardSourceType;
  outputAction: BoardOutputType;
  outputBehaviour: BoardOutputBehaviour;
  extendedConfigJson: string;
  extendedConfigParsed: IBoardGroupsConfig;
  boardCellColor: string;
  columns: BoardColumn[] = [];

  constructor(config: BoardConfiguration | RestBoardModel) {
    if (isBoardResponse(config)) {
      const columns = JSON.parse(config.boardColumnsConfig);
      if (Array.isArray(columns)) {
        this.columns = columns.filter(e => isBoardColumn(e));
        this.columns.forEach(c => (c.id = c.id ?? uuidv4()));

        const propertyColumnIndex = this.columns.findIndex(c => c.type == BoardColumnType.PropertyName);
        let unitColumnIndex = this.columns.findIndex(c => c.type == BoardColumnType.Unit);

        if (propertyColumnIndex === -1 || propertyColumnIndex !== unitColumnIndex + 1) {
          // make Property columns always next after Unit
          if (propertyColumnIndex !== -1) {
            this.columns.splice(propertyColumnIndex, 1);
          }
          unitColumnIndex = this.columns.findIndex(c => c.type == BoardColumnType.Unit);

          this.columns.splice(unitColumnIndex + 1, 0, {
            id: uuidv4(),
            name: 'Property',
            type: BoardColumnType.PropertyName,
            editable: false,
            visible: true,
            frozen: false,
          });
        }
      } else {
        this.initDefaultColumns();
      }
      this.id = config.id;
      this.name = config.name;
      this.progressType = config.progressType;
      this.boardType = config.boardType;
      this.datasource = config.datasource;
      this.outputAction = config.outputAction;
      this.outputBehaviour = config.outputBehaviour;
      this.selectedPortfolioIds = config.selectedPortfolioIds
        ? config.selectedPortfolioIds.split(',').map(Number)
        : undefined;
      this.allPortfolios = config.allPortfolios;
      this.extendedConfigJson = config.extendedConfigJson;
      this.extendedConfigParsed = config.extendedConfigJson ? JSON.parse(config.extendedConfigJson) : null;
      this.boardCellColor = config.boardCellColor ?? '#ffffff';
    } else {
      extend(this, config);
      this.initDefaultColumns();
    }
  }

  addColumn(
    name: string,
    type: BoardColumnType,
    editable = true,
    workflowStep = null,
    visible = true,
    frozen = false,
    poisition?: number
  ): string {
    const id = uuidv4();
    const column = {
      id,
      name,
      type,
      workflowStep,
      editable,
      visible,
      frozen,
    };
    if (poisition === undefined) {
      this.columns.push(column);
    } else {
      this.columns.splice(poisition, 0, column);
    }
    return id;
  }

  private initDefaultColumns() {
    this.addColumn('Unit', BoardColumnType.Unit, false, null, true, true);
    this.addColumn('Property', BoardColumnType.PropertyName, false, null, true, true);

    switch (this.boardType) {
      case BoardType.MakeReady:
        this.addColumn('Conversation', BoardColumnType.Comments);
        this.addColumn('Move out', BoardColumnType.MoveOut, false);
        this.addColumn('Move in', BoardColumnType.MoveIn, false);
        this.addColumn('Avail', BoardColumnType.Available, true);
        this.addColumn('Trash out', BoardColumnType.Simple);
        this.addColumn('Paint', BoardColumnType.Simple);
        this.addColumn('Flooring', BoardColumnType.Simple);
        this.addColumn('Clean', BoardColumnType.Simple);
        break;
      case BoardType.Reno:
        this.addColumn('Conversation', BoardColumnType.Comments);
        this.addColumn('Move out', BoardColumnType.MoveOut, false);
        this.addColumn('Move in', BoardColumnType.MoveIn, false);
        this.addColumn('Avail', BoardColumnType.Available, true);
        this.addColumn('Demo', BoardColumnType.Simple);
        this.addColumn('Paint', BoardColumnType.Simple);
        this.addColumn('Flooring', BoardColumnType.Simple);
        this.addColumn('Trimout', BoardColumnType.Simple);
        this.addColumn('Appliances', BoardColumnType.Simple);
        this.addColumn('Final clean', BoardColumnType.Simple);
        break;
      case BoardType.Marketing:
        this.addColumn('Conversation', BoardColumnType.Comments);
        this.addColumn('Type', BoardColumnType.UnitType, false);
        this.addColumn('Finish', BoardColumnType.Finish, false);
        this.addColumn('Move out', BoardColumnType.MoveOut, false);
        this.addColumn('Avail', BoardColumnType.Available, true);
        this.addColumn('Adv.Rent', BoardColumnType.AdvertisedRent, false);
        this.addColumn('Market Rent', BoardColumnType.MarketRent, false);
        break;
      case BoardType.Eviction:
        this.addColumn('Conversation', BoardColumnType.Comments);
        this.addColumn('NTV', BoardColumnType.Simple);
        this.addColumn('Eviction Filed', BoardColumnType.Simple);
        this.addColumn('Hearing', BoardColumnType.Simple);
        this.addColumn('Judgment', BoardColumnType.Simple);
        this.addColumn('Appeal', BoardColumnType.Simple);
        this.addColumn('Writ Filed', BoardColumnType.Simple);
        this.addColumn('Writ Posted', BoardColumnType.Simple);
        this.addColumn('Writ Executed', BoardColumnType.Simple);
        break;
      case BoardType.MoveIn:
        this.addColumn('Conversation', BoardColumnType.Comments);
        this.addColumn('Move in', BoardColumnType.MoveIn, false);
        this.addColumn('Application', BoardColumnType.Simple);
        this.addColumn('Set MI Date', BoardColumnType.Simple);
        this.addColumn('Lease signed', BoardColumnType.Simple);
        this.addColumn('Key prep', BoardColumnType.Simple);
        this.addColumn('Final Sparkle', BoardColumnType.Simple);
        this.addColumn('Key handover', BoardColumnType.Simple);
        this.addColumn('MI Inspection', BoardColumnType.Simple);
        this.addColumn('PMS MoveIn', BoardColumnType.Simple);
        break;
      case BoardType.MoveOut:
        this.addColumn('Conversation', BoardColumnType.Comments);
        this.addColumn('Move out', BoardColumnType.MoveOut, false);
        this.addColumn('Move in', BoardColumnType.MoveIn, false);
        this.addColumn('Inspection', BoardColumnType.Simple);
        this.addColumn('Bid', BoardColumnType.Simple);
        this.addColumn('Keys', BoardColumnType.Simple);
        this.addColumn('Deposit', BoardColumnType.Simple);
        this.addColumn('PMS MoveOut', BoardColumnType.Simple);
        this.addColumn('Marketing', BoardColumnType.Simple);
        break;
      case BoardType.Approval:
        this.addColumn('Conversation', BoardColumnType.Comments);
        this.addColumn('Move in', BoardColumnType.MoveIn, false);
        this.addColumn('Avail', BoardColumnType.Available, true);
        this.addColumn('Blue Tape', BoardColumnType.Simple);
        this.addColumn('Final Clean', BoardColumnType.Simple);
        break;
      case BoardType.Custom:
        this.addColumn('Conversation', BoardColumnType.Comments);
        this.addColumn('Move out', BoardColumnType.MoveOut, false);
        this.addColumn('Anything', BoardColumnType.Simple);
        this.addColumn('your', BoardColumnType.Simple);
        this.addColumn('heart', BoardColumnType.Simple);
        this.addColumn('desires!', BoardColumnType.Simple);
        break;
      case BoardType.LeaseUp:
        this.addColumn('Conversation', BoardColumnType.Comments);
        this.addColumn('Pics done', BoardColumnType.Simple);
        this.addColumn('ILS Posted', BoardColumnType.Simple);
        break;
      case BoardType.Prelease:
        this.addColumn('Conversation', BoardColumnType.Comments);
        this.addColumn('Finish', BoardColumnType.NewFinish, false);
        this.addColumn('Total Days', BoardColumnType.TotalDays, false);
        this.addColumn('Adv.Rent', BoardColumnType.AdvertisedRent, false);
        this.addColumn('Market Rent', BoardColumnType.MarketRent, false);
        this.addColumn('Lease Signed', BoardColumnType.Simple);
        this.addColumn('Deposit', BoardColumnType.Simple);
        this.addColumn('Forms Complete', BoardColumnType.Simple);
        this.addColumn('Sparkle', BoardColumnType.Simple);
        this.addColumn('Key hand off', BoardColumnType.Simple);
        break;
      default:
        this.addColumn('Conversation', BoardColumnType.Comments);
        this.addColumn('Trash out', BoardColumnType.Simple);
        this.addColumn('Hardware', BoardColumnType.Simple);
        break;
    }
  }
}

export type Sorting = 'ascending' | 'descending' | null;

export class ColumnSorting {
  columnId: string;
  sortFunction: (
    e1: RestTurnoverTaskModel & { turnoverData?: { unit: RestUnitModel } },
    e2: RestTurnoverTaskModel & { turnoverData?: { unit: RestUnitModel } }
  ) => number;

  constructor(column: BoardColumn, sorting: Sorting, primaryValue: boolean, private cellValueHelper: CellValueHelper) {
    this.columnId = column.id;
    const fieldFunction = this.getComparedValueFunction(column, this.columnId, primaryValue);
    this.sortFunction =
      fieldFunction === null
        ? null
        : (e1, e2) => {
            const value1 = fieldFunction(e1);
            const value2 = fieldFunction(e2);

            if (sorting && column.type === BoardColumnType.Unit) {
              const buildingNumber1 = e1?.turnoverData?.unit?.buildingNumber;
              const buildingNumber2 = e2?.turnoverData?.unit?.buildingNumber;
              if (buildingNumber1 && buildingNumber2)
                return sorting === 'ascending' ? buildingNumber1 - buildingNumber2 : buildingNumber2 - buildingNumber1;

              const unitName1 = e1?.unitName?.toLowerCase() || '';
              const unitName2 = e2?.unitName?.toLowerCase() || '';
              return sorting === 'ascending' ? unitName1.localeCompare(unitName2) : unitName2.localeCompare(unitName1);
            }
            let isUnitDown1 = false;
            let isUnitDown2 = false;

            // isUnitDown logic works for null sorting and does not work for sorting by units
            if (sorting === null || !(column.type === BoardColumnType.Unit && primaryValue === true)) {
              isUnitDown1 = e1.turnoverData.unit.isUnitDown;
              isUnitDown2 = e2.turnoverData.unit.isUnitDown;
            }

            // rules for null sorting
            if (sorting === null) {
              if (isUnitDown1 !== isUnitDown2) {
                return isUnitDown1 ? 1 : -1;
              }
              return 0;
            }

            // empty cells of the isUnitDown units
            if (value1 === 0 && isUnitDown1 && !(value2 === 0 && isUnitDown2)) {
              return 1;
            } else if (!(value1 === 0 && isUnitDown1) && value2 === 0 && isUnitDown2) {
              return -1;
            }

            // empty cells of the !isUnitDown units
            if (value1 === 0 && value2 !== 0) {
              return 1;
            } else if (value1 !== 0 && value2 === 0) {
              return -1;
            } else if (value1 === 0 && value2 === 0) {
              return 0;
            }

            // NOT empty cells of the isUnitDown units
            if (isUnitDown1 !== isUnitDown2) {
              return isUnitDown1 ? 1 : -1;
            }

            // general rules of sorting
            if (value1 !== value2) {
              return (value1 > value2 ? 1 : -1) * (sorting === 'ascending' ? 1 : -1);
            }

            return 0;
          };
  }

  private getComparedValueFunction(
    column: BoardColumn,
    columnId: string,
    primaryValue: boolean
  ): (e: RestTurnoverTaskModel & { turnoverData?: { unit: RestUnitModel } }) => string | number {
    return primaryValue ? e => this.cellValueHelper.getComparableValue(column, e, columnId) : e => e.phasePriority;
  }
}

export class BoardColumn {
  id: string;
  editable: boolean;
  name: string;
  type: BoardColumnType;
  workflowStep?: WorkflowStepEnumType;
  inspectionTemplateId?: number;
  frozen = false;
  visible = true;
  options?: DropdownColumnOption[];
  colorBg?: string;
  colorText?: string;

  constructor(config: Partial<BoardColumn>) {
    Object.assign(this, config);
    this.id = this.id ?? uuidv4();
  }
}

function isBoardColumn(entity: any): entity is BoardColumn {
  return typeof entity.name === 'string' && typeof entity.type === 'string';
}

export const isColumnSummable = (column: BoardColumn): boolean => {
  return [
    BoardColumnType.CustomAmount,
    BoardColumnType.Simple,
    BoardColumnType.UnitTotal,
    BoardColumnType.Budget,
    BoardColumnType.Delta,
  ].includes(column.type);
};

export const isCostColumn = (column: BoardColumn): boolean => {
  return [BoardColumnType.CustomAmount, BoardColumnType.Simple].includes(column.type);
};

export const ProgressTypeList: IRadioButtonOption<BoardProgressType>[] = [
  {
    label: 'N/A',
    value: BoardProgressType.None,
  },
  {
    label: 'MAKE READY',
    value: BoardProgressType.UpdateMakeReadyProgressBar,
  },
  {
    label: 'RENO',
    value: BoardProgressType.UpdateRENOProgressBar,
  },
  {
    label: 'NOTICE',
    value: BoardProgressType.UpdateNoticeProgressBar,
  },
  {
    label: 'MOVE IN',
    value: BoardProgressType.UpdateMoveInProgressBar,
  },
  {
    label: 'MOVE OUT',
    value: BoardProgressType.UpdateMoveOutProgressBar,
  },
  {
    label: 'APPROVAL',
    value: BoardProgressType.UpdateApprovalProgressBar,
  },
  {
    label: 'LEASE UP',
    value: BoardProgressType.UpdateLeaseUpProgressBar,
  },
];

export const BoardSourceTypeList: IRadioButtonOption<BoardSourceType>[] = [
  {
    label: 'MAKE READY',
    value: BoardSourceType.MakeReadyUnits,
  },
  {
    label: 'RENO',
    value: BoardSourceType.RenoUnits,
  },
  {
    label: 'Unleased',
    value: BoardSourceType.UnleasedUnits,
  },
  {
    label: 'NOTICE',
    value: BoardSourceType.OnNoticeUnits,
  },
  {
    label: 'MOVE OUT',
    value: BoardSourceType.MoveOutUnits,
  },
  {
    label: 'NOTICE and Unleased',
    value: BoardSourceType.OnNoticeAndUnleasedUnits,
  },
  {
    label: 'NOTICE under Eviction',
    value: BoardSourceType.UnitsInNoticeUnderEviction,
  },
  {
    label: 'APPROVAL',
    value: BoardSourceType.UnitsInApproval,
  },
  {
    label: 'LEASE UP',
    value: BoardSourceType.LeaseUpUnits,
  },
  {
    label: 'Prelease',
    value: BoardSourceType.PreleaseUnits,
  },
  {
    label: 'MOVE IN',
    value: BoardSourceType.UnitsInMoveIn,
  },
  {
    label: 'PUNCH',
    value: BoardSourceType.UnitsInPunch,
  },
  {
    label: 'Non-PMS',
    value: BoardSourceType.NonPmsUnits,
  },
  {
    label: 'N/A',
    value: BoardSourceType.Manual,
  },
  {
    label: 'Unleased (Not Ready)',
    value: BoardSourceType.UnleasedUnitsNotReady,
  },
  {
    label: 'Unleased (Ready)',
    value: BoardSourceType.UnleasedUnitsReady,
  },
];

export const BoardTypeList: IRadioButtonOption<BoardType>[][] = [
  [
    {
      label: 'Make Ready',
      value: BoardType.MakeReady,
    },
    {
      label: 'Reno',
      value: BoardType.Reno,
    },
    {
      label: 'Marketing',
      value: BoardType.Marketing,
    },
    {
      label: 'Lease Up',
      value: BoardType.LeaseUp,
    },
    {
      label: 'Prelease',
      value: BoardType.Prelease,
    },
    {
      label: 'Eviction',
      value: BoardType.Eviction,
    },
    {
      label: 'Move In',
      value: BoardType.MoveIn,
    },
    {
      label: 'Move Out',
      value: BoardType.MoveOut,
    },
    {
      label: 'Approval',
      value: BoardType.Approval,
    },
    {
      label: 'Non-PMS',
      value: BoardType.NonPms,
    },
  ],
  [
    {
      label: 'Custom',
      value: BoardType.Custom,
    },
  ],
];

export const BoardOutputBehaviourList: IRadioButtonOption<BoardOutputBehaviour>[] = [
  {
    label: 'Automatically',
    value: BoardOutputBehaviour.Automatically,
  },
  {
    label: 'Manually',
    value: BoardOutputBehaviour.Manually,
  },
];

export const BoardOutputTypeList: IRadioButtonOption<BoardOutputType>[] = [
  {
    label: 'N/A',
    value: BoardOutputType.None,
  },
  {
    label: 'APPROVAL',
    value: BoardOutputType.SendToApproval,
  },
  {
    label: 'LEASE UP/MOVE IN',
    value: BoardOutputType.SendToLeaseUpOrMoveIn,
  },
  {
    label: 'MOVE OUT',
    value: BoardOutputType.SendToMoveOut,
  },
  {
    label: 'MAKE READY',
    value: BoardOutputType.SendToMakeReady,
  },
  {
    label: 'PUNCH',
    value: BoardOutputType.SendToPunch,
  },
  {
    label: 'RENO',
    value: BoardOutputType.SendToReno,
  },
  {
    label: 'ARCHIVE',
    value: BoardOutputType.SendToArchive,
  },
];

export const disallowSourceTypeOutputTypeMap: Record<number, number> = {
  [BoardSourceType.MakeReadyUnits]: BoardOutputType.SendToMakeReady,
  [BoardSourceType.UnitsInPunch]: BoardOutputType.SendToPunch,
  [BoardSourceType.RenoUnits]: BoardOutputType.SendToReno,
  [BoardSourceType.MoveOutUnits]: BoardOutputType.SendToMoveOut,
  [BoardSourceType.UnitsInMoveIn]: BoardOutputType.SendToLeaseUpOrMoveIn,
  [BoardSourceType.UnitsInApproval]: BoardOutputType.SendToApproval,
};

export function getNextStepType(outputAction: BoardOutputType, turnover: RestTurnoverTaskModel): WorkflowStepEnumType {
  switch (outputAction) {
    case BoardOutputType.SendToLeaseUpOrMoveIn:
      return !!turnover.turnoverData.dateMoveIn ? WorkflowStepEnumType.MoveIn : WorkflowStepEnumType.LeaseUp;
    case BoardOutputType.SendToApproval:
      return WorkflowStepEnumType.Approval;
    case BoardOutputType.SendToMoveOut:
      return WorkflowStepEnumType.MoveOut;
    case BoardOutputType.SendToReno:
      return WorkflowStepEnumType.Reno;
    case BoardOutputType.SendToMakeReady:
      return WorkflowStepEnumType.MakeReady;
    case BoardOutputType.SendToPunch:
      return WorkflowStepEnumType.Punch;
    case BoardOutputType.SendToArchive:
      return WorkflowStepEnumType.Archive;
    case BoardOutputType.None:
      return null;
    default:
      return WorkflowStepEnumType.Approval;
  }
}

export function getCurrentStep(sourceType: BoardSourceType): WorkflowStepEnumType {
  switch (sourceType) {
    case BoardSourceType.MakeReadyUnits:
      return WorkflowStepEnumType.MakeReady;
    case BoardSourceType.RenoUnits:
      return WorkflowStepEnumType.Reno;
    case BoardSourceType.OnNoticeUnits:
      return WorkflowStepEnumType.Notice;
    case BoardSourceType.MoveOutUnits:
      return WorkflowStepEnumType.MoveOut;
    case BoardSourceType.MarketingUnits:
      return WorkflowStepEnumType.Marketing;
    case BoardSourceType.OnNoticeAndUnleasedUnits:
      return WorkflowStepEnumType.Notice;
    case BoardSourceType.UnitsInNoticeUnderEviction:
      return WorkflowStepEnumType.Notice;
    case BoardSourceType.UnitsInMoveIn:
      return WorkflowStepEnumType.MoveIn;
    case BoardSourceType.UnitsInApproval:
      return WorkflowStepEnumType.Approval;
    case BoardSourceType.UnitsInPunch:
      return WorkflowStepEnumType.Punch;
    default:
      return WorkflowStepEnumType.MoveOut;
  }
}

export class BoardFilter {
  searchStr?: string;
  events?: EventActivityFilter[];
  types?: string[];
  finish?: number[];
  assignees?: number[];
  properties?: number[];
  schedule?: string[];
  status?: ScheduleCellStatus[];
  boards?: number[];

  //Not used but keeped controls
  startMoveInDate?: Date;
  endMoveInDate?: Date;
  startMoveOutDate?: Date;
  endMoveOutDate?: Date;
  startAvailableDate?: Date;
  endAvailableDate?: Date;
  startShowableDate?: Date;
  endShowableDate?: Date;
}

export class BoardEvent {
  name: string;
  id: string;
  colorScheme: number;
}

export interface EventActivityFilter {
  eventType: number;
  label: string;
  colorScheme: number;
  value?: any;
}

export interface DeleteUnitFromBoardI {
  unitName: string;
  id: number;
}

export interface BoardLayoutInspectionTemplatesUsage {
  boardLayoutId: number;
  columnId: string;
  inspectionTemplateId: number;
  pushToPMS: boolean;
  ticketTopicId?: number;
}
