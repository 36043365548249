import { UserType } from '@shared/enums/user-type';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';

export const UserTypeListConst: IRadioButtonOption<UserType>[] = [
  {
    value: UserType.Employee,
    label: 'Employee',
  },
  {
    value: UserType.Contractor,
    label: 'Vendor',
  },
  {
    value: UserType.Resident,
    label: 'Resident',
  },
];
