import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, filter, of, switchMap, take, tap } from 'rxjs';

import { RestapiService } from '@app/services/restapi.service';
import { TurnoversService } from '@app/services/turnovers.service';
import { CommonInMemmoryUpdatingService } from '@main-application/shared/services/common-in-memmory-updating.service';
import { selectUserPermissionLevel } from '@main-application/store/selectors/user.selectors';
import { PermissionLevelType } from '@shared/enums/permission-level.enum';
import { checkPermissionLevel } from '@shared/functions/check-permission-level.function';
import { RestTurnoverTaskModel } from '@shared/interfaces/turnover.interface';
import { DialogResult } from '@ui-components/modals/config/dialog-result.enum';
import { ModalsService } from '@ui-components/modals/modals.service';

import { BoardLayoutInspectionTemplatesUsage, RestBoardModel } from '../interfaces/board';

@Injectable()
export class BoardService extends CommonInMemmoryUpdatingService<RestBoardModel, void> {
  private readonly route = 'boards/';
  boardRemoving$ = new BehaviorSubject(0);

  constructor(private service: RestapiService, private modalsService: ModalsService, private store: Store<{}>) {
    super();
  }

  getTurnoverList(boardId: number, portfolioId: number) {
    return this.service.getData<RestTurnoverTaskModel[]>(
      `turnovers/bb/${boardId}/${portfolioId}`,
      TurnoversService.fixTimezoneForTurnoverTaskModel
    );
  }

  refresh() {
    if (this._list$ && !this._list$.closed) {
      return this.getListInternal().pipe(tap(list => this._list$.next(list)));
    }
    return of(null);
  }

  getListInternal() {
    return this.service.getData<RestBoardModel[]>(this.route + 'list');
  }

  protected deleteInternal(id: number): Observable<void> {
    return this.service.post(this.route + `delete/${id}`);
  }

  getListByPortfolio(portfolioId: number) {
    return this.service.getData<RestBoardModel[]>(this.route + 'getByPortfolio/' + portfolioId);
  }

  add(entity: RestBoardModel) {
    return this.service
      .post<number>(this.route + 'add', entity)
      .pipe(tap(id => this.updateSavedData(list => [...list, { ...entity, id: id }])));
  }

  update(entity: RestBoardModel) {
    return this.service.post(this.route + 'update', entity).pipe(
      tap(() =>
        this.updateSavedData(list => {
          const index = list.findIndex(e => e.id === entity.id);
          const copyOfArray = [...list];
          if (index >= 0) {
            copyOfArray.splice(index, 1, entity);
          }
          return copyOfArray;
        })
      )
    );
  }

  removeBoard(boardName: string, boardId: number) {
    return this.store.select(selectUserPermissionLevel).pipe(
      take(1),
      filter(
        (permissionLevel: PermissionLevelType[]) =>
          !!permissionLevel?.length && checkPermissionLevel(permissionLevel, PermissionLevelType.Board_Edit)
      ),
      switchMap(() =>
        this.modalsService
          .openConfirmationModal({
            title: `Delete board: "${boardName}"?`,
            content: `Board will be deleted permanently along with its data.`,
            confirmColor: 'warn',
          })
          .afterClosed()
          .pipe(
            take(1),
            filter(result => result === DialogResult.Success),
            tap(() => this.boardRemoving$.next(boardId)),
            switchMap(() => this.delete(boardId))
          )
      )
    );
  }

  getTemplatesUsage(id: number): Observable<BoardLayoutInspectionTemplatesUsage[]> {
    return this.service.getData<BoardLayoutInspectionTemplatesUsage[]>(`${this.route}${id}/templatesUsage`);
  }

  updateTemplateUsage(usage: BoardLayoutInspectionTemplatesUsage): Observable<void> {
    return this.service.update<void>(`${this.route}updateTemplateUsage`, usage);
  }
}
