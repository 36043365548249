<div class="display-flex flex-column">
  <div mat-dialog-title class="display-flex modal-header">
    <div class="subtitle">{{ header }}</div>
    <ng-container [ngTemplateOutlet]="userType"></ng-container>
  </div>

  <mat-dialog-content>
    <form [formGroup]="form">
      <ng-template #userType>
        <app-radio-button-dropdown
          *ngIf="form"
          [label]="'User type:'"
          [isLabelInside]="true"
          [isSmallFont]="true"
          [labelRequired]="true"
          [dividerPosition]="0"
          [attrDisable]="!!data.user && !data.preselect"
          [allowClear]="false"
          [allowSearch]="false"
          [autoSortList]="false"
          [showValidationError]="false"
          [formControl]="userGroupType"
          [optionCss]="'inspection-format-option'"
          [items]="userTypeList"
          attrAppendTo="body"></app-radio-button-dropdown>
      </ng-template>
      <ng-container *ngIf="form">
        <div class="display-flex flex-column grid-gap-16">
          <div class="display-flex grid-gap-16" *ngIf="userGroupType?.value === UserType.Resident">
            <app-radio-button-dropdown
              class="w-50"
              [items]="properties$ | async"
              [allowSearch]="(properties$ | async)?.length > 7"
              [attrDisable]="!(properties$ | async)?.length"
              [label]="'Property'"
              [formControl]="propertyId"
              [allowClear]="true"
              [listLoading]="selectPropertiesLoading$ | async"
              [attrPlaceholder]="(selectPropertiesLoading$ | async) ? 'Loading properties…' : 'Select property'"
              [labelRequired]="true"
              [showValidationError]="isSubmitted"
              attrAppendTo="body"></app-radio-button-dropdown>
            <app-radio-button-dropdown
              class="w-50"
              [items]="units$ | async"
              [allowSearch]="(units$ | async)?.length > 7"
              [attrDisable]="!(units$ | async)?.length || !propertyId?.value"
              [label]="'Unit'"
              [formControl]="unitId"
              [allowClear]="false"
              [labelRequired]="true"
              [listLoading]="unitListLoading$ | async"
              [attrPlaceholder]="(unitListLoading$ | async) ? 'Loading units…' : 'Select unit'"
              [showValidationError]="isSubmitted"
              attrAppendTo="body"></app-radio-button-dropdown>
          </div>
          <div class="display-flex grid-gap-16">
            <app-input
              class="w-50"
              [attrLabel]="'First name'"
              [contentCss]="'display-flex flex-column fill'"
              [formControl]="firstName"
              [attrPlaceholder]="!firstName.value ? 'Enter first name' : ''"
              [labelRequired]="true"
              [showValidationError]="isSubmitted"></app-input>
            <app-input
              class="w-50"
              [attrLabel]="'Last name'"
              [contentCss]="'display-flex flex-column fill'"
              [formControl]="lastName"
              [attrPlaceholder]="!lastName.value ? 'Enter last name' : ''"
              [labelRequired]="true"
              [showValidationError]="isSubmitted"></app-input>
          </div>

          <div class="display-flex grid-gap-16">
            <app-input
              class="w-50"
              [attrLabel]="'Email'"
              [contentCss]="'display-flex flex-column fill'"
              [formControl]="email"
              [attrPlaceholder]="!email.value ? 'Enter email' : ''"
              [labelRequired]="true"
              [attrDisable]="!!data.user && !data.preselect"
              [showValidationError]="isSubmitted"></app-input>
            <app-input
              class="w-50"
              [attrMask]="'(000) 000-0000'"
              [attrLabel]="'Phone number'"
              [contentCss]="'display-flex flex-column fill'"
              [formControl]="phoneNumber"
              [attrPlaceholder]="!phoneNumber.value ? 'Enter phone number' : ''"></app-input>
          </div>

          <div class="display-flex grid-gap-16">
            <app-radio-button-dropdown
              class="w-50"
              [containerCss]="'user-preview-role'"
              [autoSortList]="false"
              [items]="userRoles"
              [label]="'Role'"
              [containerWidthCss]="'user-preview-role'"
              [formControl]="roleId"
              [allowClear]="false"
              [attrPlaceholder]="'Select role'"
              [labelRequired]="true"
              [showValidationError]="isSubmitted"
              attrAppendTo="body"></app-radio-button-dropdown>
            <app-radio-button-dropdown
              class="w-50"
              [label]="'Native language'"
              [attrAppendTo]="'body'"
              [allowClear]="false"
              [allowSearch]="false"
              [items]="nativeLanguages"
              [showValidationError]="isSubmitted"
              [formControl]="nativeLanguage"></app-radio-button-dropdown>
          </div>

          <div class="display-flex grid-gap-16">
            <app-radio-button-dropdown
              class="w-100"
              *ngIf="userGroupType?.value === UserType.Contractor"
              [containerCss]="'user-preview-role'"
              [autoSortList]="false"
              [items]="companyRadioList"
              [label]="'Company'"
              [containerWidthCss]="'user-preview-role'"
              [formControl]="companyId"
              [allowClear]="true"
              [attrPlaceholder]="'Select company'"
              [labelRequired]="true"
              [listLoading]="companyListLoading$ | async"
              [showValidationError]="isSubmitted"
              [allowAddNew]="true"
              [addNewLabel]="'+ Add new vendor'"
              (addNewClicked)="addNewVendor()"
              attrAppendTo="body"></app-radio-button-dropdown>
            <app-select-tree
              *ngIf="userGroupType?.value !== UserType.Resident"
              class="w-100"
              [label]="'Portfolio/Properties'"
              (selectedRoots)="selectedRootsChange($event)"
              [nodes]="root"
              [isSuperUser]="isSuperUser"
              [selectedNodes]="propertiesToSelect"
              [warningMessage]="warningMessage"></app-select-tree>
          </div>
        </div>
      </ng-container>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="display-flex grid-gap-8 justify-content-end w-100">
      <button mat-stroked-button color="warn" class="modal-button" (click)="close()">Cancel</button>
      <button mat-raised-button color="primary" class="modal-button" [disabled]="inProgress$ | async" (click)="save()">
        <div class="display-flex align-items-center grid-gap-4">
          {{ userGroupType.value === UserType.Resident ? saveResidentButtonText : saveButtonText }}
          <mat-spinner *ngIf="inProgress$ | async" color="accent" diameter="20"></mat-spinner>
        </div>
      </button>
    </div>
  </mat-dialog-actions>
</div>
