import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FileUploadsService } from '@app/services/file-uploads.service';
import { PhotoViewerComponent } from '@main-application/inspections/components/photo-viewer/photo-viewer.component';
import {
  InspectionItemStatus,
  RestInspectionContents,
} from '@main-application/inspections/models/rest-inspections-model.interface';
import { InspectionService } from '@main-application/inspections/services/inspection.service';
import {
  BooleanQuestion,
  EmojiDetails,
  ImageDetails,
  ResidentInspection,
  ResidentInspectionArea,
  ResidentInspectionItem,
} from '@main-application/resident/pages/inspect/inspect.component';
import { ResidentInspectionsService } from '@main-application/resident/services/resident-inspections.service';
import { ButtonMenuComponent } from '@shared/components/button/button-menu/button-menu.component';
import { imageVideoFileExtensions } from '@shared/constants/file-extensions.const';
import { EColorPalette } from '@shared/enums/color-palette.enum';
import { EIcon } from '@shared/enums/icon.enum';
import { LocalStorageDataEnum } from '@shared/enums/local-storage-data.enum';
import { FileUploadModel } from '@shared/interfaces';
import { AttachmentItem } from '@shared/interfaces/attachment-item';
import { PipesModule } from '@shared/pipes/pipes.module';
import { propertyStateValidator } from '@shared/validators/propertystate.validator';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';
import { IconComponent } from '@ui-components/components/icon/icon.component';
import { MultipleAttachmentsModule } from '@ui-components/components/multiple-attachments/multiple-attachments.module';

import { TemplateEditorDialogModule } from '../../../main-application/shared/template-editor-dialog/template-editor-dialog.module';

@Component({
  selector: 'app-resident-inspect-details',
  templateUrl: './resident-inspect-details.component.html',
  styleUrls: ['./resident-inspect-details.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    IconComponent,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    TemplateEditorDialogModule,
    ButtonMenuComponent,
    MultipleAttachmentsModule,
    PipesModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResidentInspectDetailsComponent implements OnInit {
  cleanInputAttachment = new EventEmitter<void>();
  EColorPalette = EColorPalette;
  EIcon = EIcon;
  inspectForm: FormGroup;
  dialogRef: MatDialogRef<any>;
  currentItemIndex = 0;
  totalItems: number;
  onSubmitOptions = [
    {
      label: 'Done & Next',
      id: false,
    },
    {
      label: 'Done',
      id: true,
    },
  ];
  onSubmitSelected = this.onSubmitOptions[0];
  inspectionId: number;
  inspectionContentId: number;
  isCommentRequired = false;
  isPhotoRequired = false;
  mappedImages = [];
  savedMappedImages = [];
  thumbnailPlaceholder = '../../../../../../../assets/icon/zoom-in.svg';
  isUploading = false;
  hasChanges = false;
  isPhotoViewerOpened = false;
  currentArea: ResidentInspectionArea = null;
  currentAreaIndex = 0;
  initialEmojiDetails: EmojiDetails[] = [];
  initialFormData: any;
  imageVideoFileExtensions = imageVideoFileExtensions;

  @ViewChild('instructionsSheet') instructionsSheet: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      id: number;
      residentInspection: ResidentInspection;
      areaId: string;
      startIndex: number;
    },
    private dialog: MatDialog,
    private bottomSheetRef: MatBottomSheetRef<ResidentInspectDetailsComponent>,
    private cdr: ChangeDetectorRef,
    private inspectionService: InspectionService,
    private residentInspectionsService: ResidentInspectionsService,
    private fileUploadsService: FileUploadsService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.currentAreaIndex = this.data.residentInspection.areas.findIndex(area => area.id === this.data.id);
    this.currentArea = this.data.residentInspection.areas[this.currentAreaIndex];

    this.currentItemIndex = this.data.startIndex;
    this.totalItems = this.currentArea.items.length;

    this.initializeForm(this.currentArea.items[this.currentItemIndex]);

    this.bottomSheetRef.backdropClick().subscribe(() => {
      this.close();
    });

    const instructionKey = this.getInstructionKey(this.currentArea.items[this.currentItemIndex]);
    if (
      !this.isInstructionDismissed(instructionKey) &&
      this.currentArea.items[this.currentItemIndex].hasInstructions &&
      this.currentArea.items[this.currentItemIndex].instructions
    ) {
      setTimeout(() => {
        this.openInstructions(this.currentArea.items[this.currentItemIndex].instructions);
      }, 300);
    }
  }

  private initializeForm(data: ResidentInspectionItem) {
    this.inspectionId = data.inspectionId;
    this.inspectionContentId = data.id;
    this.initialEmojiDetails = [];

    if (!this.initialEmojiDetails.length) {
      this.initialEmojiDetails = data.emojiDetails ? [...data.emojiDetails.map(emoji => ({ ...emoji }))] : [];
    }

    if (this.inspectForm) this.inspectForm.reset();
    this.inspectForm = this.fb.group({
      emojiDetails: [this.initialEmojiDetails.map(emoji => ({ ...emoji })), propertyStateValidator],
      itemName: [data.itemName || '', Validators.required],
      booleanQuestions: this.fb.array(this.createBooleanQuestions(data.booleanQuestions || [])),
      comment: [data.comment || null],
      fileUploadIds: [data.fileUploadIds || []],
    });

    this.initialFormData = JSON.parse(JSON.stringify(this.inspectForm.getRawValue()));

    this.mappedImages = [];
    this.loadFormDataFromLocalStorageOrProcessFileUploadIds(data);

    if (data.emojiDetails && data.emojiDetails.length > 0) {
      this.setValidatorsBasedOnPropertyState();
    } else {
      this.setValidatorsWithoutPropertyState(data);
    }

    this.inspectForm.valueChanges.subscribe(() => {
      this.saveFormDataToLocalStorage(data);
    });

    this.cdr.detectChanges();
  }

  public setEmojiDefault(index: number) {
    const emojis = this.inspectForm.get('emojiDetails').value;
    emojis.forEach((emoji: EmojiDetails, i: number) => {
      emoji.isDefault = i === index;
    });
    this.inspectForm.get('emojiDetails').setValue(emojis);
    this.inspectForm.get('emojiDetails').updateValueAndValidity();

    this.setValidatorsBasedOnPropertyState();
  }

  private setValidatorsWithoutPropertyState(data: ResidentInspectionItem) {
    this.isCommentRequired = data.commentRequired;
    this.isPhotoRequired = data.photoRequired;

    const commentControl = this.inspectForm.get('comment');
    if (this.isCommentRequired) {
      commentControl.setValidators(Validators.required);
    } else {
      commentControl.clearValidators();
    }
    commentControl.updateValueAndValidity();

    const fileUploadIdsControl = this.inspectForm.get('fileUploadIds');
    if (this.isPhotoRequired && this.mappedImages.length === 0) {
      fileUploadIdsControl.setValidators(Validators.required);
    } else {
      fileUploadIdsControl.clearValidators();
    }
    fileUploadIdsControl.updateValueAndValidity();

    this.cdr.detectChanges();
  }

  private setValidatorsBasedOnPropertyState() {
    const emojis = this.inspectForm.get('emojiDetails').value;
    const selectedEmoji = emojis.find((emoji: EmojiDetails) => emoji.isDefault);

    this.isCommentRequired = selectedEmoji?.onSelect?.includes('commentRequired') || false;
    this.isPhotoRequired = selectedEmoji?.onSelect?.includes('photoRequired') || false;

    const commentControl = this.inspectForm.get('comment');
    if (this.isCommentRequired) {
      commentControl.setValidators(Validators.required);
    } else {
      commentControl.clearValidators();
    }
    commentControl.updateValueAndValidity();

    const fileUploadIdsControl = this.inspectForm.get('fileUploadIds');
    if (this.isPhotoRequired && this.mappedImages.length === 0) {
      fileUploadIdsControl.setValidators(Validators.required);
    } else {
      fileUploadIdsControl.clearValidators();
    }
    fileUploadIdsControl.updateValueAndValidity();

    this.cdr.detectChanges();
  }

  private createBooleanQuestions(questions: BooleanQuestion[] = []): FormGroup[] {
    return questions.map(question =>
      this.fb.group({
        question: [question.question, Validators.required],
        answer: [
          question.answer === 'true'
            ? true
            : question.answer === 'false'
            ? false
            : question.defaultAnswer === 'true'
            ? true
            : question.defaultAnswer === 'false'
            ? false
            : null,
          Validators.required,
        ], // BE requires true and false in the form of strings
        guid: [question.guid],
      })
    );
  }

  get booleanQuestions(): FormArray {
    return this.inspectForm.get('booleanQuestions') as FormArray;
  }

  public handleAttachment(attachments: AttachmentItem[]) {
    if (attachments?.length) {
      attachments.forEach(attachment => {
        const fileData = attachment.upload;
        this.mappedImages.push({
          cloudUri: fileData.cloudUri,
          fileUploadId: fileData.id,
          thumbnailUri: fileData.thumbnailUri || null,
          videoDuration: fileData.videoDurationInSeconds || null,
        });
      });

      Promise.resolve().then(() => {
        const key = this.residentInspectionsService.generateItemSpecificKey(
          this.currentArea.items[this.currentItemIndex]
        );
        localStorage.setItem(`${key}-images`, JSON.stringify(this.mappedImages));
      });

      this.setValidatorsBasedOnPropertyState();
      this.cleanInputAttachment.emit();
      this.cdr.detectChanges();
    }
  }

  public viewAllPhotos(images: ImageDetails[], isEditorMode = true, initialIndex = 0) {
    if (!images || images.length === 0) {
      return;
    }

    const imageUrls = this.mappedImages.map(image => ({
      cloudUri: image.cloudUri,
      thumbnailFileUploadCloudUri: image.thumbnailUri,
    }));

    if (imageUrls.length > 0) {
      const config: MatDialogConfig = {
        maxWidth: '100vw',
        minWidth: 'min(500px, 100vw)',
        height: '100vh',
        panelClass: 'photo-viewer-modal-container',
        data: {
          imageUrls,
          isEditorMode,
          initialIndex,
        },
      };

      this.isPhotoViewerOpened = true;
      const dialogRef = this.dialog.open(PhotoViewerComponent, config);

      dialogRef.afterClosed().subscribe(result => {
        this.isPhotoViewerOpened = false;
        if (result) {
          if (!result.deletedImages) return;
          const deletedImages = result.deletedImages || [];
          const deletedFileUploadIds = this.mappedImages
            .filter(image => deletedImages.includes(image.cloudUri))
            .map(image => image.fileUploadId);

          const updatedFileUploadIds = this.inspectForm
            .get('fileUploadIds')
            .value.filter((id: number) => !deletedFileUploadIds.includes(id));
          this.inspectForm.get('fileUploadIds').setValue(updatedFileUploadIds);

          this.mappedImages = this.mappedImages.filter(image => !deletedFileUploadIds.includes(image.fileUploadId));

          const key = this.residentInspectionsService.generateItemSpecificKey(
            this.currentArea.items[this.currentItemIndex]
          );
          localStorage.setItem(`${key}-images`, JSON.stringify(this.mappedImages));

          if (this.areFileUploadIdsEqual(this.mappedImages, this.savedMappedImages)) {
            localStorage.removeItem(`${key}-images`);
          }

          this.cdr.markForCheck();
        }
      });
    }
  }

  public submitForm(isDone = false) {
    if (this.inspectForm.invalid) {
      this.inspectForm.markAllAsTouched();
      setTimeout(() => {
        this.scrollToFirstInvalidControl();
      }, 100);
      return;
    }

    if (this.inspectForm.valid) {
      this.hasChanges = true;
      const updatedData = this.inspectForm.value;
      const currentItem = this.currentArea.items[this.currentItemIndex];

      const allFileUploadIds = [
        ...new Set([...(updatedData.fileUploadIds || []), ...this.mappedImages.map(image => image.fileUploadId)]),
      ];

      currentItem.surveyJSON.pages.forEach((page, pageIndex) => {
        page.elements.forEach((element, elementIndex) => {
          if (element.type === 'comment') {
            currentItem.surveyJSON.pages[pageIndex].elements[elementIndex].value = updatedData.comment;
          }

          if (element.type === 'file' && element.guid === updatedData.guid) {
            currentItem.surveyJSON.pages[pageIndex].elements[elementIndex].mainImage = allFileUploadIds;
          }

          if (element.type === 'boolean') {
            const updatedBoolean = updatedData.booleanQuestions?.find(q => q.question === element.title);
            if (updatedBoolean) {
              currentItem.surveyJSON.pages[pageIndex].elements[elementIndex].value = updatedBoolean.answer;
            }
          }
        });
      });

      this.currentArea.items[this.currentItemIndex] = {
        ...currentItem,
        emojiDetails: updatedData.emojiDetails,
        comment: updatedData.comment,
        booleanQuestions: updatedData.booleanQuestions,
        fileUploadIds: allFileUploadIds,
        surveyJSON: currentItem.surveyJSON,
      };

      const answer = this.residentInspectionsService.generateAnswer(
        this.currentArea.items,
        this.currentArea.items[this.currentItemIndex].uuid
      );
      const isCompleted = this.currentArea.items.every((item: ResidentInspectionItem) =>
        this.residentInspectionsService.getCompletedItem(item)
      );

      const payload = {
        id: this.currentArea.id,
        inspectionId: this.currentArea.inspectionId,
        inspectionTemplateAreaId: this.currentArea.inspectionTemplateAreaId,
        answerJSON: answer,
        status: isCompleted ? InspectionItemStatus.Completed : InspectionItemStatus.Incompleted,
      } as RestInspectionContents;

      this.inspectionService.queueSubmitAnswers(payload);

      const contentElement = document.querySelector('.inspect-form-content');
      contentElement.classList.add('fade-out');

      setTimeout(() => {
        contentElement.classList.remove('fade-out');
        if (isDone) {
          this.clearFormDataFromLocalStorage(this.currentArea.items[this.currentItemIndex]);
          this.bottomSheetRef.dismiss({ result: this.hasChanges });
        } else if (this.currentItemIndex === this.totalItems - 1) {
          if (this.currentAreaIndex < this.data.residentInspection.areas.length - 1) {
            this.clearFormDataFromLocalStorage(this.currentArea.items[this.currentItemIndex]);
            this.currentAreaIndex++;
            this.currentArea = this.data.residentInspection.areas[this.currentAreaIndex];
            this.currentItemIndex = 0;
            this.totalItems = this.currentArea.items.length;
            this.initializeForm(this.currentArea.items[this.currentItemIndex]);
          }
        } else {
          this.clearFormDataFromLocalStorage(this.currentArea.items[this.currentItemIndex]);
          this.currentItemIndex++;
          this.initializeForm(this.currentArea.items[this.currentItemIndex]);
        }
      }, 400);
    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = document.querySelector('form .ng-invalid') as HTMLElement;

    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      firstInvalidControl.focus();
    }
  }

  public markAs(id: boolean): void {
    this.onSubmitSelected = this.onSubmitOptions.find(el => el.id === id);
  }

  public close(): void {
    if (this.isUploading) {
      this.snackbarService.error('Please wait until the file uploads are complete.');
      return;
    }
    this.bottomSheetRef.dismiss({ result: this.hasChanges });
  }

  @HostListener('document:keydown.escape', ['$event'])
  public onEscapeKeyDown(event: KeyboardEvent): void {
    if (!this.isPhotoViewerOpened) {
      this.close();
    }
  }

  public openInstructions(instructions: string) {
    this.dialogRef = this.dialog.open(this.instructionsSheet, {
      hasBackdrop: true,
      autoFocus: false,
      panelClass: 'instructions-container',
      position: { bottom: '0px' },
      width: '100vw',
      minWidth: '100vw',
      maxWidth: '100vw',
      data: { instructions },
    });
  }

  public closeInstructions(doNotShowAgain = false): void {
    if (doNotShowAgain) {
      const instructionKey = this.getInstructionKey(this.currentArea.items[this.currentItemIndex]);
      this.markInstructionAsDismissed(instructionKey);
    }
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private getInstructionKey(item: ResidentInspectionItem): string {
    return `${LocalStorageDataEnum.INSTRUCTIONS_DISMISSED}-${item.id}-${item.inspectionId}-${item.inspectionTemplateAreaId}-${item.uuid}`;
  }

  private isInstructionDismissed(key: string): boolean {
    return localStorage.getItem(key) === 'true';
  }

  private markInstructionAsDismissed(key: string): void {
    localStorage.setItem(key, 'true');
  }

  private saveFormDataToLocalStorage(item: ResidentInspectionItem) {
    if (this.inspectForm.valid) {
      const formData = JSON.parse(JSON.stringify(this.inspectForm.getRawValue()));
      const isEqualToInitial = this.deepEqual(this.initialFormData, formData);

      if (!isEqualToInitial) {
        const key = this.residentInspectionsService.generateItemSpecificKey(item);
        localStorage.setItem(key, JSON.stringify(formData));
      } else {
        this.clearFormDataFromLocalStorage(item);
      }
    }
  }

  private loadFormDataFromLocalStorageOrProcessFileUploadIds(item: ResidentInspectionItem) {
    const key = this.residentInspectionsService.generateItemSpecificKey(item);
    const storedData = localStorage.getItem(key);
    const storedImages = localStorage.getItem(`${key}-images`);

    if (storedImages) {
      this.mappedImages = JSON.parse(storedImages);
      const fileUploadIds = this.inspectForm.get('fileUploadIds').value;
      this.savedMappedImages = this.mappedImages.filter(image => fileUploadIds.includes(image.fileUploadId));
    } else {
      this.mappedImages = [];
    }

    if (storedData) {
      const parsedStoredData = JSON.parse(storedData);

      if (this.isLocalStorageDataCorrupted(parsedStoredData)) {
        console.error('Something went wrong. Local storage cleared.');
        this.clearFormDataFromLocalStorage(item);
        this.processFileUploadIds(item.fileUploadIds, item);
      } else {
        this.inspectForm.patchValue(parsedStoredData);
        this.processFileUploadIds(parsedStoredData.fileUploadIds, item);
      }
    } else {
      this.processFileUploadIds(item.fileUploadIds, item);
    }

    this.cdr.detectChanges();
  }

  private isLocalStorageDataCorrupted(storedData: any): boolean {
    const initialEmojis = this.initialFormData.emojiDetails;
    const storedEmojis = storedData.emojiDetails;
    if (initialEmojis?.length !== storedEmojis?.length) {
      return true;
    }

    const initialQuestions = this.initialFormData.booleanQuestions;
    const storedQuestions = storedData.booleanQuestions;
    if (initialQuestions?.length !== storedQuestions?.length) {
      return true;
    }

    if (!storedData.itemName || !storedData.fileUploadIds) {
      return true;
    }

    return false;
  }

  private processFileUploadIds(fileUploadIds: number[], item: ResidentInspectionItem) {
    fileUploadIds.forEach(fileUploadId => {
      if (!this.mappedImages.some(img => img.fileUploadId === fileUploadId)) {
        this.fileUploadsService
          .search({
            ids: [fileUploadId],
            inspectionId: item.inspectionId,
            inspectionContentId: item.id,
          })
          .subscribe(result => {
            if (result?.length) {
              const fileData: FileUploadModel = result[0];
              const imageData = {
                fileUploadId: fileData.id,
                cloudUri: fileData.cloudUri,
                thumbnailUri: fileData.thumbnailFileUploadCloudUri || null,
                videoDuration: fileData.videoDurationInSeconds || null,
              };

              this.mappedImages.push({ ...imageData });

              this.savedMappedImages = JSON.parse(JSON.stringify(this.mappedImages));
              this.cdr.markForCheck();
            }
          });
      }
    });
  }

  private clearFormDataFromLocalStorage(item: ResidentInspectionItem) {
    const key = this.residentInspectionsService.generateItemSpecificKey(item);
    localStorage.removeItem(key);
    localStorage.removeItem(`${key}-images`);
  }

  public userClearFormDataFromLocalStorage(item: ResidentInspectionItem): void {
    const key = this.residentInspectionsService.generateItemSpecificKey(item);
    localStorage.removeItem(key);
    localStorage.removeItem(`${key}-images`);
    this.initializeForm(item);
  }

  private deepEqual(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  private areFileUploadIdsEqual(arr1: any[], arr2: any[]): boolean {
    const fileUploadIds1 = arr1.map(image => image.fileUploadId).sort();
    const fileUploadIds2 = arr2.map(image => image.fileUploadId).sort();

    return JSON.stringify(fileUploadIds1) === JSON.stringify(fileUploadIds2);
  }

  public hasUnsavedData(item: ResidentInspectionItem): boolean {
    const localStorageKey = this.residentInspectionsService.generateItemSpecificKey(item);
    const hasFormData = !!localStorage.getItem(localStorageKey);
    const hasImagesData = !!localStorage.getItem(`${localStorageKey}-images`);

    return hasFormData || hasImagesData;
  }
}
