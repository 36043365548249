import { Injectable } from '@angular/core';
import { find, isEmpty, omitBy } from 'lodash';
import { Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestapiService } from '@app/services/restapi.service';
import { PermissionLevelType } from '@shared/enums/permission-level.enum';
import { UserType } from '@shared/enums/user-type';
import { getUserItems } from '@shared/functions/get-user-list.function';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { UserClaims } from '@shared/interfaces/user-claims';
import { UserData } from '@shared/interfaces/user-data';
import { UserWithGeneralRole } from '@shared/interfaces/user-with-general-role';
import {
  InviteResidentUserModel,
  InviteRestUserModel,
  RestResidentUserModel,
  RestUserModel,
  UpdateResidentUserModel,
  UpdateRestUserModel,
  UserFilter,
} from '@shared/interfaces/user.interface';

import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private restApiService: RestapiService, private cacheService: CacheService) {}

  getCachedUserList(isActive?: boolean): Observable<RestUserModel[]> {
    return this.cacheService
      .getCachedInfo('getUserList', 0, () => this.getUserList())
      .pipe(map(list => (typeof isActive !== 'boolean' ? list : list.filter(e => e.isActive === isActive))));
  }

  getCachedUserListOptions(isActive = true): Observable<IRadioButtonOption<RestUserModel>[]> {
    return this.getCachedUserList(isActive).pipe(map(list => getUserItems(list)));
  }

  search(
    filter: UserFilter,
    isResident = false
  ): Observable<{ result: RestResidentUserModel[] | RestUserModel[]; totalCount: number }> {
    const reqData = {
      userGroupTypes: filter.userGroupTypes?.length ? filter?.userGroupTypes?.join(',') : null,
      keyword: filter.search ? filter.search : null,
      pageNumber: filter.pn,
      pageSize: filter.ps,
      sortBy: filter.sortBy,
      isDesc: filter.isDesc,
      roleId: filter.role,
      propertyId: filter.propertyId,
      isActive: filter.isActive,
    };
    if (filter.portfolioId !== -1) reqData['portfolioId'] = filter.portfolioId;

    if (isResident) {
      return this.restApiService.create<{ result: RestResidentUserModel[]; totalCount: number }>(
        'users/search-residents',
        reqData
      );
    }

    return this.restApiService.create<{ result: RestUserModel[]; totalCount: number }>('users/search', reqData);
  }

  getUserList(filter?: UserFilter): Observable<RestUserModel[]> {
    let url = 'Users/';
    if (filter) {
      url += '?';
      if (filter.userGroupTypes?.length) {
        url += `userGroupTypes=${filter.userGroupTypes.join(',')}&`;
      }
      if (filter.portfolioId) {
        url += `portfolioId=${filter.portfolioId}&`;
      }
      if (filter.propertyId) {
        url += `propertyId=${filter.propertyId}&`;
      }
      if (filter.search) {
        url += `search=${filter.search}&`;
      }
      if (filter.pn >= 0) {
        url += `pn=${filter.pn}&`;
      }
      if (filter.ps >= 0) {
        url += `ps=${filter.ps}&`;
      }
      if (filter.role >= 0) {
        url += `role=${filter.role}&`;
      }
      if (filter.sortBy >= 0) {
        url += `sortBy=${filter.sortBy}&`;
      }
      if (filter.isDesc) {
        url += `isDesc=${filter.isDesc}&`;
      }
    }

    return this.restApiService.getData<RestUserModel[]>(url);
  }

  getCachedPropertyUserList(propertyId: number, isActive?: boolean): Observable<RestUserModel[]> {
    return this.cacheService
      .getCachedInfo('getPropertyUserList', propertyId, () => this.getPropertyUserList(propertyId))
      .pipe(map(list => (typeof isActive !== 'boolean' ? list : list.filter(e => e.isActive === isActive))));
  }

  getPropertyUserList(propertyId: number, userTypes?: number[]): Observable<RestUserModel[]> {
    const getParamsUrl = new URLSearchParams(omitBy({ userTypes }, isEmpty) as any as URLSearchParams).toString();

    return this.restApiService
      .getData<RestUserModel[]>(`Users/property/${propertyId}?${getParamsUrl}`)
      .pipe(shareReplay());
  }

  getPortfolioUserList(portfolioId: number, userTypes?: number[]): Observable<RestUserModel[]> {
    const getParamsUrl = new URLSearchParams(omitBy({ userTypes }, isEmpty) as any as URLSearchParams).toString();
    return this.restApiService.getData<RestUserModel[]>(`Users/portfolio/${portfolioId}?${getParamsUrl}`);
  }

  getUserByPropertyIds(propertyIds: number[]): Observable<RestUserModel[]> {
    return this.restApiService.post<RestUserModel[]>(`users/properties`, propertyIds);
  }

  expireCashedUserData() {
    this.cacheService.expireCachedInfo('getUserData');
    this.cacheService.expireCachedInfo('getPropertyUserList');
  }

  getCashedUserData(): Observable<UserData> {
    return this.cacheService.getCachedInfo('getUserData', 0, () => this.getUserData());
  }

  private getUserData(): Observable<UserData> {
    return this.restApiService.getData<UserClaims[]>(`users/claims`).pipe(
      map(result => {
        const userId = find(result, { claim: 'DbUserId' });
        const email = find(result, { claim: 'UserEmail' });
        const name = find(result, { claim: 'name' });
        // todo - temporary
        const firstName = find(result, {
          claim: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
        })?.value;
        const lastName = find(result, {
          claim: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
        })?.value;

        const generalRole = find(result, {
          claim: 'GeneralRole',
        })?.value;

        const companyId = find(result, {
          claim: 'CompanyId',
        })?.value;

        const userType: UserType = (parseInt(
          find(result, {
            claim: 'UserTypeEnumValue',
          })?.value
        ) || 0) as UserType;

        const permissionLevel = result
          .filter(item => item.claim === 'PermissionLevel')
          .map(item => item.value as PermissionLevelType);

        return {
          email: email?.value,
          id: Number.parseInt(userId?.value),
          name: name.value,
          firstName,
          lastName,
          permissionLevel,
          generalRole,
          companyId: companyId ? parseInt(companyId, 10) : null,
          userType,
        };
      })
    );
  }

  getUserById(id: number): Observable<RestUserModel> {
    return this.restApiService.getData<RestUserModel>(`Users/${id}`);
  }

  setUserData(user: UpdateRestUserModel): Observable<RestUserModel> {
    this.expireCashedUserData();
    return this.restApiService.update<RestUserModel>(`Users/${user.id}`, user);
  }

  removeUser(user: UserWithGeneralRole): Observable<boolean> {
    this.expireCashedUserData();
    return this.restApiService.delete<boolean>(`users/${user.id}`);
  }

  reactivateUser(user: UserWithGeneralRole): Observable<RestUserModel> {
    this.expireCashedUserData();
    return this.restApiService.create<RestUserModel>(`users/reactivate?id=${user.id}`, {});
  }

  inviteUser(user: InviteRestUserModel): Observable<RestUserModel> {
    this.expireCashedUserData();
    return this.restApiService.associate<RestUserModel>(`Users/Invitations`, user);
  }

  inviteResidentUser(user: InviteResidentUserModel): Observable<RestUserModel> {
    return this.restApiService.associate<RestUserModel>(`Users/addResident`, user);
  }

  setResidentUserData(user: UpdateResidentUserModel): Observable<RestUserModel> {
    return this.restApiService.update<RestUserModel>(`Users/${user.id}/editResident`, user);
  }

  resendInvitationEmail(userId: number): Observable<any> {
    return this.restApiService.create<any>(`Users/${userId}/ResendInvitation`, {});
  }
}
