import { DashboardViewType } from '@shared/enums/dashboard-view-type';

export const propertySelectorTypes: DashboardViewType[] = [
  DashboardViewType.PROPERTY_VIEW,
  DashboardViewType.PROPERTY_WITH_GROUPING_VIEW,
  DashboardViewType.PROPERTY_WITHOUT_ALL,
];

export const allPropertyValue = -1;
export const allPropertiesOption = {
  value: allPropertyValue,
  label: 'All properties',
  sort: 0,
};
export const allPortfoliosValue = -1;
export const allPortfoliosOption = {
  value: allPortfoliosValue,
  label: 'All portfolios',
  sort: 0,
};
