<div class="display-flex align-items-center justify-content-center close-button" (click)="close()">
  <mat-icon>close_small</mat-icon>
</div>

<div class="slide">
  <ng-container *appIsVideo="currentUrl['cloudUri']; else imageTemplate">
    <video
      class="image-size"
      controls
      [class.landscape]="currentUrl.imageOrientation === ImageOrientation.LandscapeRight"
      [src]="currentUrl['cloudUri']"
      [poster]="currentUrl?.thumbnailFileUploadCloudUri || ''"
      (click)="$event.stopPropagation()"></video>
    <div
      *ngIf="data.isEditorMode"
      class="display-flex align-items-center justify-content-center delete-button"
      (click)="deleteImage(currentUrl.cloudUri)">
      <mat-icon>delete</mat-icon>
    </div>
  </ng-container>

  <ng-template #imageTemplate>
    <img
      class="image-size"
      [src]="currentUrl['cloudUri']"
      [class.landscape]="currentUrl.imageOrientation === ImageOrientation.LandscapeRight"
      (click)="$event.stopPropagation()" />
    <div
      *ngIf="data.isEditorMode"
      class="display-flex align-items-center justify-content-center delete-button"
      (click)="deleteImage(currentUrl.cloudUri)">
      <mat-icon>delete</mat-icon>
    </div>
  </ng-template>
</div>

<div class="images-list display-flex">
  <div class="thumbs display-flex" (click)="$event.stopPropagation()">
    <ng-container *ngFor="let mediaUrl of data.imageUrls">
      <ng-container *appIsVideo="mediaUrl['cloudUri']; else imageThumbTemplate">
        <video
          class="thumb-video"
          [class.landscape]="mediaUrl.imageOrientation === ImageOrientation.LandscapeRight"
          [src]="mediaUrl['cloudUri']"
          [poster]="mediaUrl?.thumbnailFileUploadCloudUri || ''"
          (click)="onChange(mediaUrl['cloudUri'])"
          [class.current]="mediaUrl['cloudUri'] === currentUrl['cloudUri']"></video>
      </ng-container>

      <ng-template #imageThumbTemplate>
        <img
          class="thumb-image"
          [class.landscape]="mediaUrl.imageOrientation === ImageOrientation.LandscapeRight"
          [src]="mediaUrl['cloudUri']"
          (click)="onChange(mediaUrl['cloudUri'])"
          [class.current]="mediaUrl['cloudUri'] === currentUrl['cloudUri']" />
      </ng-template>
    </ng-container>
  </div>
</div>
