import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMaskModule } from 'ngx-mask';

import { ModalsService } from '@app/modules/ui-components/modals/modals.service';
import { NewTurnoverModalModule } from '@app/modules/ui-components/modals/new-turnover-modal/new-turnover-modal.module';
import { WorkflowActionsService } from '@app/services/workflow-actions.service';
import { TooltipDirectiveModule } from '@shared/directives/tooltip.directive';
import { AttachmentModule } from '@ui-components/components/attachment/attachment.module';
import { DateDropdownModule } from '@ui-components/controls/date-dropdown/date-dropdown.module';
import { InputModule } from '@ui-components/controls/input/input.module';
import { InputFileModule } from '@ui-components/controls/input-file/input-file.module';
import { InputTypeFileModule } from '@ui-components/controls/input-type-file/input-type-file.module';
import { RadioButtonDropdownModule } from '@ui-components/controls/radio-button-dropdown/radio-button-dropdown.module';
import { AddTicketModalModule } from '@ui-components/modals/add-ticket-modal/add-ticket-modal.module';
import { AddVendorUserModalModule } from '@ui-components/modals/add-vendor-user-modal/add-vendor-user-modal.module';
import { GalleryModalModule } from '@ui-components/modals/gallery-modal/gallery-modal.module';
import { InfoModalModule } from '@ui-components/modals/info-modal/info-modal.module';
import { NewBulkScopeModule } from '@ui-components/modals/new-bulk-scope/new-bulk-scope.module';
import { NextStepAssigneeModalModule } from '@ui-components/modals/next-step-assignee-modal/next-step-assignee-modal.module';
import { PromptModalModule } from '@ui-components/modals/prompt-modal/prompt-modal.module';
import { TicketPreviewModalModule } from '@ui-components/modals/ticket-preview-modal/ticket-preview-modal.module';
import { UserPreviewModalModule } from '@ui-components/modals/user-preview-modal/user-preview-modal.module';

import { AddBidModalComponent } from './add-bid-modal/add-bid-modal.component';
import { AddVendorModalModule } from './add-vendor-modal/add-vendor-modal.module';
import { CommentTooLongModalModule } from './comment-too-long-modal/comment-too-long-modal.module';
import { ConfirmationModalModule } from './confirmation-modal/confirmation-modal.module';
import { TurnoverPunchModalModule } from './turnover-punch-modal/turnover-punch-modal.module';

@NgModule({
  declarations: [AddBidModalComponent],
  providers: [ModalsService, WorkflowActionsService],
  imports: [
    CommonModule,
    NewTurnoverModalModule,
    GalleryModalModule,
    NewBulkScopeModule,
    TicketPreviewModalModule,
    CommentTooLongModalModule,
    TurnoverPunchModalModule,
    InfoModalModule,
    AddTicketModalModule,
    NextStepAssigneeModalModule,
    UserPreviewModalModule,
    AddVendorModalModule,
    ConfirmationModalModule,
    AddVendorUserModalModule,
    MatDialogModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    FormsModule,
    RadioButtonDropdownModule,
    ReactiveFormsModule,
    DateDropdownModule,
    TooltipDirectiveModule,
    InputModule,
    MatInputModule,
    NgxMaskModule,
    InputFileModule,
    InputTypeFileModule,
    PromptModalModule,
    AttachmentModule,
    MatBottomSheetModule,
  ],
})
export class ModalsModule {}
