export enum EColorPalette {
  cGray = '#484848',
  cGray2 = '#DADADA',
  cGray3 = '#8A8A8A',
  cGray4 = '#212121',
  cGray5 = '#B9B8B8',
  cGray6 = '#FAFAFA',
  cGray7 = '#767575',
  cGray8 = '#EEEEEE',
  cBlack = '#000',
  cRed = '#FF6C6C',
  cRed2 = '#E93A4F',
  cRed3 = '#D72A40',
  cGreen = '#4EB08D',
  cGreen2 = '#9EBD59',
  cGreen3 = '#108060',
  cGreen4 = '#005336',
  cGreen5 = '#0f593f',
  cGreen6 = '#0b6b50',
  cGreen7 = '#129872',
  cGreen8 = '#49a986',
  cGreen9 = '#dcefe8',
  cGreen10 = '#4EB08D',
  cYellow = '#FFC700',
  cYellow2 = '#FFCD1B',
  cYellow3 = '#FFBA69',
  cBlue = '#3058C2',
  cBlue2 = '#305D93',
  cViolet = '#6969EE',
  cOrange = '#FF8A00',
  cOrange2 = '#EA912A',
  cPurple = '#9055C3',
  cPink = '#FF217E',
  cPink2 = '#C2B6FF',
  cWhite = '#FFFFFF',
}
