import { createAction, props } from '@ngrx/store';

import {
  DynamicSchedule,
  InspectionScheduleType,
  RestDynamicInspectionModel,
  RestInspectionsModel,
} from '@main-application/inspections/models/rest-inspections-model.interface';
import { RestAddInspectionTicket, RestUpdateInspectionTicket } from '@shared/interfaces/ticket.interface';
import { IInspectionFilterForm } from '@template/interfaces/filter.interface';
import { RestTemplateModel } from '@template/models/rest-template-model.interface';

export const loadAllInspections = createAction(
  '[Inspections] Load all space template',
  props<{ startDate: Date; endDate?: Date; portfolioId: number; isResident?: boolean }>()
);
export const loadAllInspectionsSuccess = createAction(
  '[Inspections] All space template loaded successfully',
  props<{ listItems: RestInspectionsModel[] }>()
);
export const loadAllInspectionsFailed = createAction('[Inspections] All space template loading failed');
export const clearAllInspections = createAction('[Inspections] clearAllInspections');

export const clearInspectionsData = createAction('[Inspections] Clear space template data');

export const loadTemplatesForUnitSuccess = createAction(
  '[Inspections] Load template for unit number success',
  props<{ list: RestTemplateModel[] }>()
);
export const loadTemplatesForUnitFailed = createAction('[Inspections] Load template for unit number failed');

export const setNewlyAddedInspection = createAction(
  '[Inspections] setNewlyAddedInspectionId',
  props<{ inspection: RestInspectionsModel | RestDynamicInspectionModel; inspectionType: InspectionScheduleType }>()
);
export const clearNewlyAddedInspectionId = createAction('[Inspections] clearNewlyAddedInspectionId');

export const loadDynamicInspections = createAction(
  '[Inspections] loadDynamicInspections',
  props<{ portfolioId: number }>()
);
export const loadDynamicInspectionsSuccess = createAction(
  '[Inspections] loadDynamicInspectionsSuccess',
  props<{ listItems: RestDynamicInspectionModel[] }>()
);
export const loadDynamicInspectionsFailed = createAction('[Inspections] loadDynamicInspectionsFailed');
export const clearDynamicInspections = createAction('[Inspections] clearDynamicInspections');

export const deleteDynamicInspection = createAction(
  '[Inspections] deleteDynamicInspection',
  props<{ inspectionId: number; dynamicallyScheduledOn: DynamicSchedule; portfolioId: number; stepName: string }>()
);
export const deleteDynamicInspectionSuccess = createAction('[Inspections] deleteDynamicInspectionSuccess');
export const deleteDynamicInspectionFailed = createAction('[Inspections] deleteDynamicInspectionFailed');

export const loadRepeatingInspections = createAction(
  '[Inspections] loadRepeatingInspections',
  props<{ portfolioId: number }>()
);
export const loadRepeatingInspectionsSuccess = createAction(
  '[Inspections] loadRepeatingInspectionsSuccess',
  props<{ listItems: RestInspectionsModel[] }>()
);
export const loadRepeatingInspectionsFailed = createAction('[Inspections] loadRepeatingInspectionsFailed');
export const clearRepeatingInspections = createAction('[Inspections] clearRepeatingInspections');

export const setInspectionsFilter = createAction(
  '[Inspections] setInspectionsFilter',
  props<Partial<IInspectionFilterForm>>()
);

export const addInspectionTicket = createAction(
  '[Inspections] addInspectionTicket',
  props<{ ticket: RestAddInspectionTicket }>()
);
export const addInspectionTicketSuccess = createAction('[Inspections] addInspectionTicketSuccess');
export const addInspectionTicketFailed = createAction('[Inspections] addInspectionTicketFailed');

export const updateInspectionTicket = createAction(
  '[Inspections] updateInspectionTicket',
  props<{ ticket: RestUpdateInspectionTicket }>()
);
export const updateInspectionTicketSuccess = createAction('[Inspections] updateInspectionTicketSuccess');
export const updateInspectionTicketFailed = createAction('[Inspections] updateInspectionTicketFailed');
