import { Injectable } from '@angular/core';

@Injectable()
export class InputErrorService {
  getValidationMessage(validator: string, errorParams: unknown): string {
    switch (validator) {
      case 'dateRangeError': {
        return 'Wrong date range';
      }
      case 'numberRangeError': {
        return 'Wrong number range';
      }
      case 'regexpError': {
        return 'Wrong format';
      }
      case 'max': {
        return 'Value too large';
      }
      case 'min': {
        return 'Value too small';
      }
      case 'digitsPositive': {
        return 'Only positive digits are allowed';
      }
      case 'digits': {
        return 'Only digits are allowed';
      }
      case 'required': {
        return '* required field';
      }
      case 'atLeastOnePhotoRequired': {
        return 'At least one photo is required';
      }
      case 'maxlength': {
        const error = errorParams as { actualLength: number; requiredLength: number };
        return `Max text length is ${error.requiredLength}`;
      }
      case 'minlength': {
        const error = errorParams as { actualLength: number; requiredLength: number };
        return `Minimum text length is ${error.requiredLength}`;
      }
      case 'kpiTaskRange': {
        return 'Wrong data range';
      }
      case 'laterThanToday': {
        return 'Due date must be later than today date ';
      }
      case 'urlGoogleDrive': {
        return 'Incorrect Google Drive URL';
      }
      case 'email': {
        return 'Incorrect email address';
      }
      case 'atLeastOneItemRequired': {
        return 'At least one item is required';
      }
      case 'url': {
        return 'Incorrect URL address';
      }

      case 'stepBeforeEnd': {
        return '"Due date" must be earlier than turnover end date';
      }

      case 'stepAfterStart': {
        return '"Due date" must be later than turnover start date';
      }

      case 'duplicatedRole': {
        return 'A role with the given name already exists';
      }

      case 'duplicatedFinish': {
        return 'A finish with the given name already exists';
      }

      case 'userAlreadyExists': {
        return 'User already exists';
      }
      case 'userInactive': {
        return "User with provided email was recently removed and can't be used again";
      }

      case 'elapsedDaysRangeEmpty': {
        return 'Empty range';
      }

      case 'elapsedDaysRangeEmptyLow': {
        return 'Empty low priority value';
      }

      case 'elapsedDaysRangeEmptyHigh': {
        return 'Empty high priority value';
      }

      case 'elapsedDaysRangeHigh': {
        return "Value in 'high priority' field must be lover, than 'low priority' field";
      }

      case 'elapsedDaysRangeLow': {
        return "Value in 'low priority' field must be lover, than 'high priority' field";
      }

      case 'customError': {
        if (typeof errorParams === 'string') {
          return errorParams;
        }
        return '';
      }

      default: {
        return '';
      }
    }
  }
}
