import { allPropertiesOption } from '@main-application/application-menu/property-selector/constants';
import { IRadioButtonOption } from '@shared/interfaces/radio-button-option.interface';
import { RestPropertyModel } from '@shared/interfaces/rest-portfolio-model.interface';

export function getPortfolioProperties(
  userProperties: RestPropertyModel[],
  includeAllProperties = false
): IRadioButtonOption<number>[] {
  return (includeAllProperties ? [allPropertiesOption as IRadioButtonOption<number>] : []).concat(
    userProperties
      .map<IRadioButtonOption<number>>(item => {
        return {
          value: item.id,
          label: item.name,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label))
  );
}
