import { Injectable } from '@angular/core';
import { Observable, catchError } from 'rxjs';

import { RestapiService } from '@app/services/restapi.service';
import { RestGenericTypedAttachment } from '@shared/interfaces/attachment.interface';
import { UpdateTypedAttachmentToEntityModel } from '@shared/interfaces/turnover.interface';
import { RestUnitModel, RestUnitSummaryModel } from '@shared/interfaces/unit.interface';
import { SnackbarErrorMessage } from '@ui-components/components/customized-snackbar/snackbar-message.enum';
import { SnackbarService } from '@ui-components/components/customized-snackbar/snackbar.service';

@Injectable()
export class UnitsService {
  constructor(private restApiService: RestapiService, private snackbarService: SnackbarService) {}

  update(unit: RestUnitModel) {
    return this.restApiService.update<RestUnitModel>(`Units/${unit.id}`, unit).pipe(
      catchError((_err, c) => {
        this.snackbarService.error(SnackbarErrorMessage.UpdatingUnit);
        return c;
      })
    );
  }

  getPropertyUnits(propertyId: number): Observable<RestUnitSummaryModel[]> {
    return this.restApiService.getData<RestUnitSummaryModel[]>(`Units/property/${propertyId}`);
  }

  setAttachment(attachmentToEntity: UpdateTypedAttachmentToEntityModel): Observable<RestGenericTypedAttachment> {
    return this.restApiService.create<RestGenericTypedAttachment>(
      `Units/${attachmentToEntity.unitId}/Attachments`,
      attachmentToEntity
    );
  }

  getUnitAttachments(unitId: number): Observable<RestGenericTypedAttachment[]> {
    return this.restApiService.getData<RestGenericTypedAttachment[]>(`Units/${unitId}/Attachments`);
  }

  getUnitById(unitId: number): Observable<RestUnitModel> {
    return this.restApiService.getData<RestUnitModel>(`Units/${unitId}`);
  }

  deleteUnitAttachment(unitId: number, attachmentId: number): Observable<boolean> {
    return this.restApiService.delete(`Units/${unitId}/Attachments/${attachmentId}`);
  }
}
