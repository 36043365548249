<div class="input-container" [ngClass]="containerCss">
  <div [ngClass]="contentCss">
    <ng-container *ngIf="attrLabel && !displaySkeleton">
      <label [ngClass]="[labelCss, labelRequired ? 'label-required' : '']" *ngIf="attrLabel && !displaySkeleton">
        {{ attrLabel }}
        <mat-icon
          class="info-icon"
          *ngIf="infoTooltip"
          [matTooltip]="infoTooltip"
          [matTooltipClass]="'multi-line-tooltip'">
          info_outlined
        </mat-icon>
      </label>
    </ng-container>
    <ng-container *ngIf="attrLabel && displaySkeleton">
      <div class="label-placeholder skeleton-loader" [ngStyle]="{ 'width.px': attrLabel.length * 10 }"></div>
    </ng-container>
  </div>
  <div class="display-flex flex-column fill">
    <div class="input-wrapper fill" [ngClass]="[inputCss, disabled ? 'disabled' : '']">
      <ng-container *ngIf="!displaySkeleton">
        <mat-form-field
          floatLabel="never"
          [ngClass]="[
            matFormCss,
            markAsInvalid ||
            (errors && showValidationErrorOrSubmitted) ||
            (displayCounter && control?.value?.length > maxCounter)
              ? 'invalid'
              : '',
            errors ? 'error-section' : '',
            control.value || control.value === 0 ? 'filled' : '',
            disabled ? 'disabled' : ''
          ]"
          [attr.aria-invalid]="errors && showValidationErrorOrSubmitted">
          <input
            aria-label="input-control"
            #inputControl
            [ngClass]="[
              (errors && showValidationErrorOrSubmitted) ||
              markAsInvalid ||
              (displayCounter && control?.value?.length > maxCounter)
                ? 'invalid'
                : '',
              errors ? 'error-section' : '',
              control.value || control.value === 0 ? 'filled' : '',
              disabled ? 'disabled' : ''
            ]"
            matInput
            [type]="attrType"
            [formControl]="control"
            [placeholder]="control.value | appInputTextPlaceholder: attrPlaceholder"
            [autocomplete]="attrAutocomplete ? 'on' : 'off'"
            [mask]="attrMask"
            [readonly]="readonly"
            [thousandSeparator]="attrThousandSeparator"
            [dropSpecialCharacters]="attrDropSpecialCharacters"
            [validation]="true"
            [appCurrencyFormatter]="useCurrencyFormatter"
            [attr.aria-invalid]="(errors && showValidationErrorOrSubmitted) || markAsInvalid"
            (blur)="onBlur($event)"
            (change)="onChanged(inputControl.value)"
            (keydown.enter)="keydownEnter($event)"
            [maxlength]="maxLength" />
          <span *ngIf="prefix" matPrefix>{{ prefix }}</span>
        </mat-form-field>
      </ng-container>
      <ng-container *ngIf="displaySkeleton">
        <div class="content-placeholder skeleton-loader w-100"></div>
      </ng-container>
    </div>
    <div
      *ngIf="!displaySkeleton && displayCounter"
      class="placeholder counter"
      [ngClass]="control?.value?.length > maxCounter ? 'red3' : ''">
      [{{ control?.value?.length || 0 }}/{{ maxCounter }}]
    </div>
    <app-input-error
      *ngIf="!displaySkeleton && errorSection && errors && displayErrorContent"
      [errors]="errors"
      [visible]="markAsInvalid || (errors && showValidationErrorOrSubmitted)"></app-input-error>
  </div>
</div>
