import { RestUnitSummaryModel } from '@shared/interfaces/unit.interface';
import { RestUserModel } from '@shared/interfaces/user.interface';
import { RestTemplateAreaModel, RestTemplateModel } from '@template/models/rest-template-model.interface';

export interface RestInspectionCreateModel {
  inspectionTemplateId: number;
  assignee: number;
  dueDate: Date;
  notifyAssignee: boolean;
  recurrencePeriod?: InspectionRecurrencePeriod;
  createOrNotifyOn?: Date;
  autoExpiredOn?: Date;
  endDate?: Date;
  propertyId?: number;
  unitId?: number;
  bulkUnitIds?: number[];
  notifyWhenDone?: boolean;
  notifyWhenDoneUserIds?: string;
  isResident?: boolean;
}

export interface RestInspectionUpdateModel extends RestInspectionCreateModel {
  id: number;
  completedBy: number;
  modifiedAt: Date;
  completedAt: Date;
  createdAt: Date;
  status: number;
  turnStepScheduleId?: number;
  calendarPosition: number;
}

export enum ScoringModels {
  None = 0,
  Maximum100 = 1,
}

export enum ScoreWeightModes {
  Automatic = 'Automatic',
  Manual = 'Manual',
}

export enum InspectionRecurrencePeriod {
  DoNotRepeat = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
  Quarterly = 4,
  SemiAnnually = 5,
  Annually = 6,
  SemiMonthly = 7,
}

export interface RestInspectionsModel {
  id: number;
  inspectionTemplateId: number;
  inspectionTemplate?: RestTemplateModel;
  unitId: number;
  completedBy: number;
  unit: RestUnitSummaryModel;
  assignee: number;
  assigneeUser: RestUserModel;
  status: InspectionStatus;
  recurrencePeriod?: InspectionRecurrencePeriod;
  inspectionContents?: RestInspectionContents[];
  completedAt: Date;
  modifiedAt: Date;
  createdAt: Date;
  dueDate: Date;
  calendarPosition: number;
  turnStepScheduleId?: number;
  inspectionType: InspectionType;
  isTurboMode?: boolean;
  attachments?: {
    attachment?: RestTypedInspectionAttachment;
    autoScopeReport?: RestTypedInspectionAttachment;
    none: RestTypedInspectionAttachment[];
  };
  totalRooms?: number;
  completedRooms?: number;
  notifyAssignee: boolean;
  createOrNotifyOn?: Date;
  autoExpiredOn?: Date;
  endDate?: Date;
  parentInspectionId?: number;
  dynamicInspectionId?: number;
  propertyId?: number;
  bulkUnitIds?: number[];
  isBulk: boolean;
  notifyWhenDone?: boolean;
  notifyWhenDoneUserIds?: string;
  scoringModel?: ScoringModels;
  resultScore?: number;
  inspectionStartedOn?: Date;
  inspectionFinishedOn?: Date;
  isResident?: boolean;
}

export enum DynamicSchedule {
  Notice = 1,
  MoveOut = 2,
  MoveIn = 3,
  MakeReady = 4,
}

export enum DynamicPropertyType {
  None = 0,
  Portfolio = 1,
  Property = 2,
  Unit = 4,
}

export interface RestDynamicInspectionUpdateModel {
  id?: number;
  inspectionTemplateId: number;
  dynamicallyScheduledOn: DynamicSchedule;
  propertyOrPortfolioId: number;
  propertyType: DynamicPropertyType;
  createOrNotifyOn: number;
  autoExpiredAfterDueDateDays: number;
  notifyAssignee: boolean;
  doNotSetAssignee: boolean;
  dueDate: number;
  assignee?: number;
  endDate: string | Date;
  notifyWhenDone?: boolean;
  notifyWhenDoneUserIds?: string;
  assignToResident?: boolean;
  propertyIds?: number[] | null;
}

export interface RestDynamicInspectionModel {
  id: number;
  inspectionTemplateId: number;
  doNotSetAssignee: boolean;
  dynamicallyScheduledOn: DynamicSchedule;
  createOrNotifyOn: number;
  autoExpiredAfterDueDateDays: number;
  notifyAssignee: boolean;
  dueDate: number;
  endDate: string | Date;
  actualAssigneeId: number;
  propertyOrPortfolioId: number;
  propertyId: number;
  propertyIds: number[];
  portfolioId: number;
  propertyType: DynamicPropertyType;
  assignee: number;
  notifyWhenDone?: boolean;
  notifyWhenDoneUserIds?: string;
  assignToResident?: boolean;
}

export interface RestTypedInspectionAttachment {
  id: number;
  dateCreated: string;
  dateUpdated: string;
  isActive: boolean;
  fileUploadId: number;
  internalName: string;
  originalFileName: string;
  size: number;
  url: string;
  attachmentTypeUrn: string;
  title: string;
  description: string;
  externalUrl: string;
  inspectionAttachmentType: number;
  trueType: number;
}

export interface RestInspectionContents {
  id: number;
  inspectionId: number;
  inspectionTemplateAreaId: number;
  sharedInspectionLibrarySpaceId: number;
  status: InspectionItemStatus;
  surveyJSON: string;
  answerJSON: string;
  inspectionTemplateAreaTitle: string;
  title: string;
  sharedInspectionLibrarySpaceTitle?: string;
  inspectionTemplateArea?: RestTemplateAreaModel;
  completedAt?: string;
  completedByUser?: RestUserModel;
  responsibleParty?: number;
  cost?: number;
  createTicketFlag?: boolean;
  inspectionStartedOn?: string;
  inspectionFinishedOn?: string;
  totalInspectionTimeInSeconds?: number;
  itemsCost?: string;
  itemsScore?: string;
  scoreWeight?: number;
  areaSubTotal?: number;
  areaTime?: number;
  areaLaborCost?: number;
  areaMaterialsCost?: number;
  ticketDetailedInformation?: string;
  images?: number[];
  ticketAssigneeId?: number;
  surveyPageId?: number;
}

export interface SendInspectionReportRequest {
  to: string[];
  subject: string;
  body: string;
}

export enum InspectionStatus {
  NotStarted,
  InProgress,
  Completed,
  Canceled,
  Expired,
}

export enum InspectionItemStatus {
  Incompleted,
  Skipped,
  Completed,
}

export enum InspectionType {
  MoveOutVacant,
  Annual,
  ServiceCheck,
  MoveIn,
  CustomInspection,
  Periodic,
  MoveOutOccupied,
  Diligence,
}

export enum PropertyInspectionType {
  Dynamic,
  Repeating,
  OneTime,
}

export enum InspectionScheduleType {
  None,
  Standard,
  Resident,
  Repeating,
  DynamicNotice,
  DynamicMoveIn,
  DynamicMoveOut,
  DynamicMakeReady,
}
