import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { InfoModalComponent } from './info-modal.component';

@NgModule({
  declarations: [InfoModalComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  exports: [InfoModalComponent],
})
export class InfoModalModule {}
