<div class="date-dropdown-container" [ngClass]="[containerCss]">
  <ng-container *ngIf="!labelInside" [ngTemplateOutlet]="labelTemplate"></ng-container>
  <ng-container *ngIf="!displaySkeleton">
    <div
      mat-button
      (click)="openDateMenu($event)"
      class="date-dropdown-button dropdown-button grid-gap-4"
      [class.date-dropdown-button--full-width]="isFullWidth"
      [ngClass]="[
        buttonCss,
        control.value ? 'active' : '',
        markAsInvalid || (errors && control.touched) ? 'invalid' : '',
        isDisabled ? 'disable' : ''
      ]">
      <div
        #dateMenuTrigger="matMenuTrigger"
        class="menu-trigger"
        [matMenuTriggerFor]="dateMenu"
        (menuOpened)="dateToggleMenu(true)"
        (menuClosed)="dateToggleMenu(false)"></div>
      <ng-container *ngIf="labelInside" [ngTemplateOutlet]="labelTemplate"></ng-container>

      <ng-container [ngTemplateOutlet]="control.value ? singleDateTemplate : placeholderTemplate"></ng-container>

      <div class="date-buttons-container display-flex justify-content-center align-items-center grid-gap-8">
        <div
          *ngIf="!isDisabled && clearable && control.value"
          mat-icon-button
          (click)="clear($event)"
          class="close-button display-flex justify-content-center">
          <app-icon
            class="close-button"
            title="Clear"
            [iconHeight]="22"
            [iconWidth]="22"
            [svg]="EIcon.CLOSE"
            [iconColor]="EColorPalette.cRed2"></app-icon>
        </div>

        <mat-icon class="date-chevron">
          {{ isExpanded ? 'arrow_drop_up' : 'arrow_drop_down' }}
        </mat-icon>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="displaySkeleton">
    <div class="content-placeholder skeleton-loader w-100"></div>
  </ng-container>

  <mat-menu #dateMenu="matMenu" xPosition="before">
    <div class="dropdown-date-select display-flex justify-content-center">
      <div
        class="dropdown-list"
        (click)="$event.stopPropagation()"
        (keydown.tab)="$event.stopPropagation()"
        (keydown.shift.tab)="$event.stopPropagation()">
        <ng-container [ngTemplateOutlet]="singleSelectionTemplate"></ng-container>
      </div>
    </div>
  </mat-menu>

  <ng-template #singleSelectionTemplate>
    <div class="calendar-section">
      <mat-calendar
        #calendar
        [minDate]="min"
        [maxDate]="max"
        [dateClass]="dateClass()"
        [headerComponent]="header"
        [selected]="dateRangeConfig"
        (selectedChange)="onSelectSingleDate($event)"></mat-calendar>
      <div class="clear-date" *ngIf="bottomContent && this.control.value && !hideBottomContent">
        <span class="clear" (click)="onClearDate()">Clear</span>
      </div>
    </div>
  </ng-template>

  <app-input-error
    *ngIf="errorSection && errors"
    [errors]="errors"
    [visible]="markAsInvalid || (errors && control.touched)"></app-input-error>
</div>

<ng-template #singleDateTemplate>
  <ng-container *ngIf="!editable; else editableTemplate">
    <div [ngClass]="valueCss">
      {{ control?.value || '' | date: displayMask }}
    </div>
  </ng-container>
</ng-template>

<ng-template #placeholderTemplate>
  <ng-container *ngIf="!editable; else editableTemplate">
    <div [ngClass]="placeholderCss">{{ attrPlaceholder }}</div>
  </ng-container>
</ng-template>

<ng-template #editableTemplate>
  <div class="editable-container" [ngClass]="valueCss">
    <input
      type="text"
      #input
      mask="M0/d0/0000"
      [formControl]="maskControl"
      (input)="updateCursor(input)"
      (blur)="valueTyped(input.value)"
      (keydown.enter)="valueTyped(input.value)"
      [leadZeroDateTime]="true"
      [attr.placeholder]="attrPlaceholder" />
  </div>
</ng-template>

<ng-template #labelTemplate>
  <ng-container *ngIf="!displaySkeleton">
    <div *ngIf="label" #labelElement class="label-container fade-truncate" [style.width]="labelGroupWidth$ | async">
      <label
        class="dropdown-label"
        [ngClass]="[
          labelCss,
          labelInside ? 'inside' : '',
          isDisabled ? 'disable' : '',
          labelRequired ? 'label-required' : ''
        ]"
        [matTooltip]="labelTooltip">
        {{ label }}
      </label>
    </div>
  </ng-container>
  <ng-container *ngIf="displaySkeleton && label">
    <div class="label-placeholder skeleton-loader" [ngStyle]="{ 'width.px': label.length * 10 }"></div>
  </ng-container>
</ng-template>
